import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import type { Language } from 'src/types/translations';

import { alternateExternalUrl } from 'src/utils/url';

export function getAgencyUrl(locale: Language, defaultLocale: Language) {
  const alternateAgencySignUp = isFeatureEnabled(
    'ENABLE_ALTERNATE_AGENCY_SIGNUP'
  );

  const agencySignUp = getProductConfig('agencySignUp');

  if (!agencySignUp) return;

  return alternateAgencySignUp
    ? alternateExternalUrl(
        agencySignUp,
        defaultLocale,
        locale,
        getProductConfig('external_crm_host')
      )
    : agencySignUp;
}
