import { useMemo } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useRouter } from 'next/router';

import { getProductConfig } from 'src/config/product';

import { useIsAlternate } from 'src/hooks/useIsAlternate';

import type { Language } from 'src/types/translations';

import { alternateAbsoluteURL, alternateExternalUrl } from 'src/utils/url';

export const useHeaderLinkConfig = () => {
  const { trans } = useTranslations();
  const isAlternate = useIsAlternate();
  const { locale, defaultLocale } = useRouter();

  return useMemo(() => {
    return getProductConfig(
      isAlternate ? 'alternateHeaderUrls' : 'headerUrls'
    ).map((config) => {
      return {
        // i18n-extract-disable-next-line
        text: trans(config.text),
        link: {
          url: config.external
            ? alternateExternalUrl(
                config.url,
                defaultLocale as Language,
                locale as Language,
                config.externalUrl
              )
            : alternateAbsoluteURL(
                config.url,
                defaultLocale as Language,
                locale as Language
              ),
        },
        isClientSideNavigation: config.isClientSideNavigation,
        props: {
          rel: 'nofollow',
        },
        isNew: false,
      };
    });
  }, [locale, defaultLocale]);
};
