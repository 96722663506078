import { type Reducer, type Ref, useRef } from 'react';

import { useAutocompleteApi } from './useAutocompleteApi';
import { useAutocompleteNavigation } from './useAutocompleteNavigation';
import { useAutocompleteSearch } from './useAutocompleteSearch';
import { useAutocompleteState } from './useAutocompleteState';

import type { AutocompleteActions, AutocompleteApi } from '../types';

type useAutocompleteParams<V> = {
  input: Ref<HTMLInputElement>;
  autocompleteApi: AutocompleteApi<V>;
  onChange?: (values: V[]) => void;
  reducer?: Reducer<V[] | undefined, AutocompleteActions<V>>;
  enabled?: boolean;
  defaultValue?: V[];
  values?: V[];
  minLength?: number;
  withBackspace?: boolean;
};

export const useAutocomplete = <V>({
  autocompleteApi,
  onChange,
  input,
  reducer = (_, a) => a.changes,
  defaultValue = [],
  minLength = 2,
  enabled = true,
  withBackspace = true,
  values: controlledValues,
}: useAutocompleteParams<V>) => {
  const { inFocus, search } = useAutocompleteSearch(input);

  const isOpen = enabled && inFocus && search.length >= minLength;

  const keyboardPositionRef = useRef<
    undefined | ReturnType<typeof useAutocompleteNavigation<V>>
  >();

  const keyboardPosition = keyboardPositionRef.current;

  const { items, loading } = useAutocompleteApi<V>(
    search,
    autocompleteApi,
    isOpen,
    (state, prev) => {
      if (state.items !== prev.items) {
        keyboardPosition?.set(0);
      }
    }
  );

  const { values, selectItem, removeItem, reset } = useAutocompleteState(
    defaultValue,
    controlledValues,
    reducer,
    onChange
  );

  keyboardPositionRef.current = useAutocompleteNavigation(
    input,
    items,
    values,
    isOpen,
    withBackspace && search === '' && values.length > 0,
    selectItem,
    removeItem
  );

  function handleItemHover(index: number) {
    return () => {
      if (keyboardPosition && keyboardPosition.value !== index) {
        keyboardPosition.set(index);
      }
    };
  }

  return {
    highlight: keyboardPosition?.value,
    setHighlight: keyboardPosition?.set,
    inFocus,
    search,
    values,
    items,
    loading,
    selectItem,
    removeItem,
    reset,
    handleItemHover,
    enabled: isOpen,
  };
};
