import { useCallback } from 'react';
import { useSetAtom } from 'jotai';

import { geographyComponentStateAtom } from '../atoms';
import { comeFromSearchOptionsAtom } from '../atoms/comeFromSearchOptionsAtom';
import type { SearchMethod } from '../atoms/searchMethodAtom';
import { searchMethodAtom } from '../atoms/searchMethodAtom';

export const useComponentControls = () => {
  const setGeographyComponentState = useSetAtom(geographyComponentStateAtom);
  const setSearchMethod = useSetAtom(searchMethodAtom);
  const setComeFromSearchOptions = useSetAtom(comeFromSearchOptionsAtom);

  const openSearchOptions = useCallback(() => {
    setGeographyComponentState((state) => ({
      ...state,
      searchOptionsOpen: true,
    }));
  }, [setGeographyComponentState]);

  const closeSearchOptions = useCallback(() => {
    setGeographyComponentState((state) => ({
      ...state,
      searchOptionsOpen: false,
    }));
  }, [setGeographyComponentState]);

  const openSearchModal = useCallback(
    (method: SearchMethod) => {
      setSearchMethod(method);
      setGeographyComponentState((state) => ({
        ...state,
        searchModalOpen: true,
      }));
    },
    [setGeographyComponentState, setSearchMethod]
  );

  const closeSearchModal = useCallback(() => {
    setSearchMethod(undefined);
    setComeFromSearchOptions(false);
    setGeographyComponentState((state) => ({
      ...state,
      searchModalOpen: false,
    }));
  }, [setComeFromSearchOptions, setGeographyComponentState, setSearchMethod]);

  const openPlaceCheckList = useCallback(() => {
    setGeographyComponentState((state) => ({
      ...state,
      placeCheckListOpen: true,
    }));
  }, [setGeographyComponentState]);

  const closePlaceCheckList = useCallback(() => {
    setGeographyComponentState((state) => ({
      ...state,
      placeCheckListOpen: false,
    }));
  }, [setGeographyComponentState]);

  const openSearchOptionsModal = useCallback(() => {
    setGeographyComponentState((state) => ({
      ...state,
      searchOptionsModalOpen: true,
    }));
  }, [setGeographyComponentState]);

  const closeSearchOptionsModal = useCallback(() => {
    setGeographyComponentState((state) => ({
      ...state,
      searchOptionsModalOpen: false,
    }));
  }, [setGeographyComponentState]);

  return {
    openSearchOptions,
    closeSearchOptions,
    openSearchModal,
    closeSearchModal,
    openPlaceCheckList,
    closePlaceCheckList,
    openSearchOptionsModal,
    closeSearchOptionsModal,
  };
};
