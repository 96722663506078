import { isFeatureEnabled } from 'src/config/features-toggle';

import type { GeographySearch } from 'src/libs/geography';
import {
  isAreaSearchGeography,
  isChildOfType,
  isCity,
  isDistanceSearchGeography,
  isPlaceSearchGeography,
  isProvince,
} from 'src/libs/geography';

import { ENTITY_TYPE } from 'src/types/geography';

export function convertPolygonsPointsToString(points: number[][]) {
  return points.map((p) => p.join(',')).join(';');
}

export const isValidGeographyForExpertAgencies = (
  geography: GeographySearch | null
) => {
  if (!geography) return false;

  const isDistanceOrAreaSearch =
    isDistanceSearchGeography(geography) || isAreaSearchGeography(geography);

  if (isFeatureEnabled('AGENCIES_EXPERT_BY_DISTANCE_OR_AREA_SEARCH')) {
    return (
      isDistanceOrAreaSearch ||
      isValidPlaceSearchGeographyForExpertAgencies(geography)
    );
  }

  return isValidPlaceSearchGeographyForExpertAgencies(geography);
};

const isValidPlaceSearchGeographyForExpertAgencies = (
  geography: GeographySearch | null
) => {
  if (!geography || !isPlaceSearchGeography(geography)) return false;

  const [placeSearchEntity] = geography.value;

  if (isFeatureEnabled('AGENCIES_EXPERT_SEARCH_LIST_PROVINCE')) {
    return (
      isProvince(placeSearchEntity) ||
      isChildOfType(placeSearchEntity, ENTITY_TYPE.province)
    );
  }

  return (
    isCity(placeSearchEntity) ||
    isChildOfType(placeSearchEntity, ENTITY_TYPE.city)
  );
};
