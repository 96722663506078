import type { Ref } from 'react';

import { useDocument } from './useDocument';
import { useListener } from './useListener';

import { refElementInterop } from './utils';

/**
 * Invoke a function when clicking outside an element
 * (Note: It works only on desktop)
 * @param ref
 * @param onClickAway
 * @param enabled
 */
export const useClickOutside = (
  ref: HTMLElement | Ref<Optional<HTMLElement>>,
  onClickAway: (event: Event) => void,
  enabled?: boolean
) => {
  const doc = useDocument();

  useListener(
    doc,
    'mousedown',
    (event) => {
      const element = refElementInterop(ref);

      if (!element) return;
      if (!element.contains(event.target as HTMLElement)) {
        onClickAway(event);
      }
    },
    enabled
  );
};
