import { AutocompleteList } from 'src/components/Autocomplete';

import { usePlaceAutocompleteContext } from '../context';

import css from './styles.module.scss';

export function SuggestionList() {
  const { autocomplete } = usePlaceAutocompleteContext();
  const {
    enabled,
    highlight,
    items,
    search,
    loading,
    handleItemHover,
    selectItem,
  } = autocomplete.autocomplete;

  if (!enabled) {
    return null;
  }

  return (
    <AutocompleteList
      highlight={highlight}
      items={items}
      searchString={search}
      loading={loading}
      onItemHover={handleItemHover}
      onClick={selectItem}
      customClass={css['in-suggestionList']}
      listContentCustomClass={css['in-suggestionList__content']}
    />
  );
}
