import { useEffect, useRef, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { geographySearchAtom } from '../atoms';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useComponentControls } from '../hooks/useComponentControls';
import { useGetEntityWithChildren } from '../hooks/useGetEntityWithChildren';

import { PepitaButton } from 'src/libs/ui/pepita-button';

import type { BaseEntityWithChildren } from 'src/types/geography';

import { isMetro } from '../utils/entity';

import { PlaceCheckListAccordion } from '../PlaceCheckListAccordion';

interface PlaceCheckListProps {
  customClass?: string;
  disableClickOutside?: boolean;
}

export function PlaceCheckList({
  disableClickOutside = false,
  customClass,
}: PlaceCheckListProps) {
  const { trans } = useTranslations();
  const elementRef = useRef<HTMLDivElement>(null);
  const { closePlaceCheckList } = useComponentControls();
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);

  const [checklistData, setChecklistData] = useState<BaseEntityWithChildren[]>(
    []
  );

  const city = useGetEntityWithChildren();

  useEffect(() => {
    setChecklistData(city?.children || []);
  }, [city]);

  const headerText: string =
    geographyData && isMetro(geographyData.value[0])
      ? trans('lbl_metro')
      : trans('lbl_zones');

  const getHeaderActionBtnTxt = () => {
    if (!geographyData) {
      return undefined;
    }

    return isMetro(geographyData.value[0])
      ? trans('search_map_header_metro_title')
      : trans('search_map_header_select_place_title');
  };

  const ref = disableClickOutside ? null : elementRef;

  useClickOutside(ref, closePlaceCheckList);

  return geographyData?.searchType === 'place' ? (
    <div
      data-cy="zones-autocomplete-list"
      ref={ref}
      className={clsx('nd-autocomplete__list', 'is-open', customClass)}
    >
      <div className="nd-autocomplete__listHead">
        <span className="nd-autocomplete__listHeadTitle">{headerText}</span>

        <PepitaButton variant="link">
          {getHeaderActionBtnTxt() ?? trans('lbl_close')}
        </PepitaButton>
      </div>
      {checklistData.length > 0 && city && (
        <PlaceCheckListAccordion checklistData={checklistData} city={city} />
      )}
    </div>
  ) : null;
}
