import type { Reducer } from 'react';
import { useCallback, useReducer } from 'react';

import type { AutocompleteActions } from '../types';

const selectionReducer = <V>(state: V[], action: AutocompleteActions<V>) => {
  switch (action.type) {
    case 'ADD_ITEM':
      return state.concat(action.item);
    case 'REMOVE_ITEM':
      return state.filter((item) => item !== action.item);
    case 'RESET':
      return [];
  }
};

export const useAutocompleteState = <V>(
  defaultValue: V[],
  controlledValues: V[] | undefined,
  reducer = (_, a) => a.changes,
  onChange?: (values: V[]) => void
) => {
  let [values, dispatch] = useReducer<Reducer<V[], AutocompleteActions<V>>>(
    (state, action) => {
      const changes = selectionReducer<V>(state, action);
      const newState = reducer(state, { ...action, changes });

      if (newState !== state && onChange) onChange(newState);

      return newState;
    },
    defaultValue
  );

  // When the hook is controlled from the outside, we only trigger the custom
  // reducer without using the react one
  if (controlledValues) {
    [values, dispatch] = [
      controlledValues,
      (action) => {
        reducer(controlledValues, action);
      },
    ];
  }

  const selectItem = useCallback((item: V) => {
    dispatch({
      type: 'ADD_ITEM',
      item,
    });
  }, []);

  const removeItem = useCallback((item: V) => {
    dispatch({
      type: 'REMOVE_ITEM',
      item,
    });
  }, []);

  const reset = useCallback(() => {
    dispatch({
      type: 'RESET',
    });
  }, []);

  return { values, selectItem, removeItem, reset };
};
