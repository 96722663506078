import type { Animation, AnimationProps } from '@pepita/animate';
import { AnimationTimeline } from '@pepita/animate';

const ANIMATION_PROPS: {
  props: AnimationProps;
  willChange: boolean;
} = {
  props: {
    duration: 250,
    easing: 'cubic-bezier(0, 0, .2, 1)',
    fill: 'both',
  },
  willChange: true,
};

export const animateAccordion = (menu: HTMLElement, reverse?: boolean) => {
  const height = menu.clientHeight;

  if (!height) return;

  const menuAnimation: Animation = {
    el: menu,
    keyframes: [
      {
        height: 0,
      },
      {
        height: `${menu.clientHeight}px`,
      },
    ],
    ...ANIMATION_PROPS,
  };

  if (reverse) {
    menuAnimation.keyframes.reverse();
  }

  const timeline = new AnimationTimeline([menuAnimation]);

  timeline.addEventListener('end', () => {
    timeline.destroy();
  });

  timeline.start();
};
