import type { TransOpts } from '@pepita-react/i18n';

import { getProductConfig } from 'src/config/product';

import type { Language } from 'src/types/translations';

export type Trans = (key: string, opts?: TransOpts) => string;

const numberFormatterMap = new Map<string, Intl.NumberFormat>();

export const squareMetersUnitMeasure = 'm²';
export const metersUnitMeasure = 'm';

export const numberFormatter = (
  lang: Language,
  value: number,
  decimals?: number
): string => {
  /**
   * Requested by Product: https://gitlab.pepita.io/indomio/indomita/website-next/issues/207
   */
  if (lang === 'es') {
    lang = 'it';
  }

  const key = `${lang}-${decimals}`;

  if (!numberFormatterMap.has(key)) {
    numberFormatterMap.set(
      key,
      new Intl.NumberFormat(lang, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals, // Same effect as a toFixed
      })
    );
  }

  return numberFormatterMap.get(key).format(value) || null;
};

export const currencyFormatter = (
  trans: Trans,
  lang: Language,
  value: number,
  currency: string,
  currencyPosition: 'before' | 'after' = 'before',
  decimals?: number,
  monthly?: boolean,
  perBed?: boolean
): string => {
  const formatted = numberFormatter(lang, value, decimals);

  const priceValue =
    currencyPosition === 'before'
      ? `${currency} ${formatted}`
      : `${formatted} ${currency}`;

  if (perBed) {
    return `${priceValue} ${trans('pricing_per_bed__edit')}`;
  }

  if (monthly) {
    return `${priceValue}/${trans('lbl_month')}`;
  }

  return priceValue;
};

interface Options {
  withTime?: boolean;
  withoutYear?: boolean;
  useProductTimeZone?: boolean;
}

export const dateFormatter = (
  timestamp: number,
  lang: Language,
  {
    withTime = false,
    withoutYear = false,
    useProductTimeZone = false,
  }: Options = {}
): Nullable<string> => {
  const timeZone = getProductConfig('timeZone');

  const options: Intl.DateTimeFormatOptions = {
    year: withoutYear ? undefined : 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: withTime ? 'numeric' : undefined,
    minute: withTime ? 'numeric' : undefined,
    timeZone: useProductTimeZone ? timeZone : undefined,
  };

  return timestamp
    ? new Date(timestamp * 1000).toLocaleDateString(lang, options)
    : null;
};

export const squareMetersFormatter = (
  lang: Language,
  meters: number,
  decimals?: number
): string => {
  const formattedSquareMeters = numberFormatter(lang, meters, decimals);

  return (
    formattedSquareMeters &&
    `${formattedSquareMeters} ${squareMetersUnitMeasure}`
  );
};

export const metersFormatter = (
  lang: Language,
  meters: number,
  decimals?: number
): string => {
  const formattedMeters = numberFormatter(lang, meters, decimals);

  return formattedMeters && `${formattedMeters} ${metersUnitMeasure}`;
};

export const byteFormatter = (bytes: number): string => {
  if (bytes > 1000000000) {
    return `${Math.round(bytes / 1000000000)} GByte`;
  } else if (bytes > 1000000) {
    return `${Math.round(bytes / 1000000)} MByte`;
  } else if (bytes > 1000) {
    return `${Math.round(bytes / 1000)} KByte`;
  }

  return `${bytes} bytes`;
};
