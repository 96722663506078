import type { PropsWithChildren, RefObject } from 'react';
import { forwardRef, useRef } from 'react';
import clsx from 'clsx';

import { usePlaceAutocomplete } from './usePlaceAutocomplete';

import { PlaceAutocompleteProvider } from './context';
import { Icon } from './Icon';
import { Input } from './Input';
import { InputContainer } from './InputContainer';
import { SuggestionList } from './SuggestionList';

import css from './styles.module.scss';

interface PlaceAutocompleteProps extends PropsWithChildren {
  customClass?: string;
  inputRef?: RefObject<HTMLInputElement>;
  hasFixedHeight?: boolean;
  error?: boolean;
}

export const PlaceAutocompleteRoot = forwardRef<
  HTMLDivElement,
  PlaceAutocompleteProps
>(function PlaceAutocompleteRoot(props, ref) {
  const defaultInputRef = useRef<HTMLInputElement>(null);

  const {
    children,
    customClass,
    error,
    hasFixedHeight,
    inputRef = defaultInputRef,
  } = props;

  // L'hook non viene richiamato direttamente dentro il provider in quanto da problemi con Typescript
  const autocomplete = usePlaceAutocomplete({ inputRef });

  return (
    <PlaceAutocompleteProvider
      autocomplete={autocomplete}
      error={error}
      hasFixedHeight={hasFixedHeight}
      inputRef={inputRef}
    >
      <div
        className={clsx(
          'nd-autocomplete',
          css['in-placeAutocomplete__autocomplete'],
          customClass
        )}
        ref={ref}
      >
        {children}
      </div>
    </PlaceAutocompleteProvider>
  );
});

export const PlaceAutocomplete = Object.assign(PlaceAutocompleteRoot, {
  InputContainer,
  Input,
  SuggestionList,
  Icon,
});
