import type { ChangeEvent, ForwardedRef, ReactNode } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';

import { useListener } from 'src/hooks/useListener';
import { useAutocomplete } from './hooks/useAutocomplete';

import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import type { AutocompleteApi, AutocompleteBaseValue } from './types';

import { nonNullable } from 'src/utils/common';
import { getAutocompleteResults } from './utils/getAutocompleteResults';

import { AutocompleteList } from './AutocompleteList';

const getInputValue = <V extends AutocompleteBaseValue>(
  value: V | null,
  searchedText: string,
  isInputFocused: boolean
) => {
  if (isInputFocused) {
    return searchedText;
  }

  if (value) {
    return value.label;
  }

  return '';
};

export interface AutocompleteRef {
  reset: () => void;
}

interface AutocompleteProps<V extends AutocompleteBaseValue> {
  name?: string;
  minLength?: number;
  defaultValue?: V;
  inputIcon?: PepitaIconProps;
  url?: string;
  webApi?: AutocompleteApi<V>;
  inputPlaceholder?: string;
  onChange?: (values: V[]) => void;
  onInput?: (search: string) => void;
  children?: ReactNode;
  inputContainerCustomClass?: string;
  inputCustomClass?: string;
  withBackspace?: boolean;
}

function AutocompleteComponent<V extends AutocompleteBaseValue>(
  {
    name,
    minLength = 2,
    defaultValue,
    url,
    webApi,
    inputPlaceholder,
    onChange,
    onInput,
    children,
    inputIcon,
    inputContainerCustomClass,
    inputCustomClass,
    withBackspace = false,
  }: AutocompleteProps<V>,
  ref: ForwardedRef<AutocompleteRef>
) {
  const inputRef = useRef<HTMLInputElement>(null);

  const autocompleteApi = webApi || getAutocompleteResults(url);

  const autocomplete = useAutocomplete<V>({
    input: inputRef,
    minLength,
    autocompleteApi,
    defaultValue: [defaultValue].filter(nonNullable),
    withBackspace,
    reducer: (_, action) => {
      switch (action.type) {
        case 'ADD_ITEM':
          return [action.item];
      }

      return action.changes;
    },
    onChange,
  });

  const { values, search, inFocus, reset } = autocomplete;

  const value = values.length > 0 ? values[0] : null;
  const inputValue = getInputValue(value, search, inFocus);

  useListener(inputRef, 'change', (evt) => {
    evt.stopPropagation();
  });

  useImperativeHandle(ref, () => ({ reset }), [reset]);

  return (
    <div className="nd-autocomplete">
      <div
        className={clsx(
          'nd-autocomplete__inputContainer',
          inputContainerCustomClass
        )}
      >
        {inputIcon && <PepitaIcon {...inputIcon} />}

        <input
          className={clsx('nd-autocomplete__input', inputCustomClass)}
          ref={inputRef}
          value={inputValue}
          type="text"
          onInput={(e: ChangeEvent<HTMLInputElement>) => {
            if (onInput) {
              onInput(e.target.value);
            }
          }}
          placeholder={inputPlaceholder}
        />
        {children}
      </div>

      {autocomplete.enabled && (
        <AutocompleteList
          highlight={autocomplete.highlight}
          items={autocomplete.items}
          searchString={autocomplete.search}
          loading={autocomplete.loading}
          onItemHover={autocomplete.handleItemHover}
          onClick={autocomplete.selectItem}
        />
      )}

      {name && (
        <input
          type="hidden"
          name={name}
          value={value ? JSON.stringify(value) : ''}
        />
      )}
    </div>
  );
}

export const Autocomplete = forwardRef(AutocompleteComponent) as <
  V extends AutocompleteBaseValue,
>(
  props: AutocompleteProps<V> & { ref?: ForwardedRef<AutocompleteRef> }
) => ReturnType<typeof AutocompleteComponent>;

export * from './AutocompleteList';
export * from './hooks/useAutocomplete';
export { AUTOCOMPLETE_DEBOUNCE_TIME } from './hooks/useAutocompleteApi';
export * from './types';
