import type { Reducer } from 'react';
import { useReducer } from 'react';

import { useDocument } from 'src/hooks/useDocument';
import { useListener } from 'src/hooks/useListener';

const Keys = { Up: 38, Down: 40 } as const;

export const useKeyboardNavigation = (max: number, enabled: boolean) => {
  const [value, dispatch] = useReducer<Reducer<number, number | string>>(
    (p, a) => {
      if (typeof a === 'number') {
        p = a;
      } else if (a === '+') {
        p++;
      } else if (a === '-') {
        p--;
      }

      return Math.max(0, Math.min(p, max - 1));
    },
    0
  );

  function prev() {
    dispatch('-');
  }

  function next() {
    dispatch('+');
  }

  function set(value: number) {
    dispatch(value);
  }

  function handleKeyDown(evt: KeyboardEvent) {
    switch (evt.keyCode || evt.key) {
      case Keys.Up:
      case 'ArrowUp':
        evt.preventDefault();
        prev();
        break;
      case Keys.Down:
      case 'ArrowDown':
        evt.preventDefault();
        next();
        break;
    }
  }

  const document = useDocument();

  useListener(document, 'keydown', handleKeyDown, enabled);

  return {
    value,
    prev,
    next,
    set,
  };
};
