import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

export function LoaderItem() {
  const { trans } = useTranslations();

  return (
    <li
      className={clsx(
        'nd-stackItem',
        'nd-autocomplete__listItem',
        'nd-autocomplete__listItem--loader'
      )}
    >
      <span className="nd-stackItem__graphic">
        <PepitaIcon name="loader" customClass="is-spinning" />
      </span>
      <span className="nd-stackItem__text">{trans('lbl_loading')}</span>
    </li>
  );
}
