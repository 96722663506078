import { ENTITY_TYPE } from '@indomita-website/map-polygons/src/types';

import { createAtomicStateStateAtom } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/GeographySearch';
import { getGeographyEntityID } from 'src/components/GeographySearch/utils';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';
import { initFormConfig } from './config/formConfig';
import type { formConfigType } from './config/formConfigTypes';
import { FormFields } from './config/formConfigTypes';

import { CATEGORIES, CONTRACT } from 'src/constants/typologies';

import type { FormStateType } from './types';

import { deepEqual } from 'src/utils/object';
import { resetForm, resetUnchangedFields } from './utils/formStateHelpers';
import { initFormStateData } from './utils/formStateInitializer';

export const searchModalStateAtom = createAtomicStateStateAtom<FormStateType>({
  key: 'searchModalState',
  default: {},
  setup(self, { watch, effect, set, get }) {
    // Using prev form state to reset filters on contract and category change
    let searchModalPrevFormState = null;

    watch(() => {
      set(self, get(formStateAtom));
      searchModalPrevFormState = get(formStateAtom);
    }, [formStateAtom]);

    effect(() => {
      const formConfig = get(formConfigAtom);
      const state = get(self);

      // Every time I change contract, category or typology I need to clean the other fields
      const newForm = resetForm(state, formConfig);

      if (
        searchModalPrevFormState &&
        (state[FormFields.CONTRACT] !==
          searchModalPrevFormState[FormFields.CONTRACT] ||
          state[FormFields.CATEGORY] !==
            searchModalPrevFormState[FormFields.CATEGORY])
      ) {
        const newState = {
          ...newForm,
          [FormFields.CONTRACT]: state[FormFields.CONTRACT],
          [FormFields.CATEGORY]: state[FormFields.CATEGORY],
          [FormFields.TYPOLOGY]: state[FormFields.TYPOLOGY],
        };

        set(self, newState);
        searchModalPrevFormState = newState;
      } else {
        searchModalPrevFormState = state;
      }
    }, [self]);
  },
  deepEqualityCheck: true,
});

const shouldFallbackToDefaultSort = (prevSearch, currentSearch): boolean => {
  if (!prevSearch || isFeatureEnabled('LISTING_SEARCH_NEW_SORTING')) {
    return false;
  }

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [FormFields.SORT_ORDER]: prevOrder,
    [FormFields.SORT_CRITERION]: prevCriterion,
    ..._prevSearch
  } = prevSearch;

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [FormFields.SORT_ORDER]: order,
    [FormFields.SORT_CRITERION]: criterion,
    ...search
  } = currentSearch;

  const isSearchEqual = deepEqual(_prevSearch, search);

  return !isSearchEqual && !prevCriterion && !criterion;
};

export const formStateAtom = createAtomicStateStateAtom<FormStateType>({
  key: 'searchFormState',
  default: () => initFormStateData({}, []),
  deepEqualityCheck: true,
  setup(self, { effect, watch, set, get }) {
    // Using prev form state to reset filters on contract and category change
    let searchFormPrevFormState = null;

    effect(() => {
      const formConfig = get(formConfigAtom);
      const state = get(self);
      const newForm = resetUnchangedFields(
        state,
        searchFormPrevFormState,
        formConfig
      );

      let sortCriterion = state[FormFields.SORT_CRITERION];
      let sortOrder = state[FormFields.SORT_ORDER];

      if (shouldFallbackToDefaultSort(searchFormPrevFormState, state)) {
        // Every time the user change a filter with the automatic sort
        // criterion we change it to relevance criterion
        sortCriterion = 'rilevanza';
        sortOrder = null;
      }

      if (
        searchFormPrevFormState &&
        (state[FormFields.CONTRACT] !==
          searchFormPrevFormState[FormFields.CONTRACT] ||
          state[FormFields.CATEGORY] !==
            searchFormPrevFormState[FormFields.CATEGORY])
      ) {
        const newState = {
          ...newForm,
          [FormFields.SORT_CRITERION]: sortCriterion,
          [FormFields.SORT_ORDER]: sortOrder,
          [FormFields.CONTRACT]: state[FormFields.CONTRACT],
          [FormFields.CATEGORY]: state[FormFields.CATEGORY],
          [FormFields.TYPOLOGY]: state[FormFields.TYPOLOGY],
          [FormFields.LICENSE]: state[FormFields.LICENSE],
        };

        set(self, newState);
        searchFormPrevFormState = newState;
      } else {
        if (state[FormFields.SORT_CRITERION] !== sortCriterion) {
          // We need to update the sort criterion
          const newState = {
            ...state,
            [FormFields.SORT_CRITERION]: sortCriterion,
            [FormFields.SORT_ORDER]: sortOrder,
          };

          set(self, newState);
          searchFormPrevFormState = newState;
        } else {
          // We do not need to update the state
          searchFormPrevFormState = state;
        }
      }

      watch(() => {
        // We need to reset categories for international searches
        const geography = get(geographySearchAtom);
        const nationID = getGeographyEntityID(geography, ENTITY_TYPE.country);
        const currentState = get(self);

        if (
          isFeatureEnabled('INTERNATIONAL_SEARCH_ONLY_RESIDENTIAL') &&
          nationID &&
          nationID !== getProductConfig('countryCode') &&
          (currentState[FormFields.CATEGORY] !== CATEGORIES.RESIDENZIALE ||
            currentState[FormFields.CONTRACT] === CONTRACT.ASTE)
        ) {
          set(self, {
            ...currentState,
            [FormFields.CONTRACT]:
              currentState[FormFields.CONTRACT] === CONTRACT.ASTE
                ? CONTRACT.VENDITA
                : currentState[FormFields.CONTRACT],
            [FormFields.CATEGORY]: CATEGORIES.RESIDENZIALE,
            [FormFields.TYPOLOGY]: null,
          });
        }
      }, [geographySearchAtom]);
    }, [self]);
  },
});

export const formConfigAtom = createAtomicStateStateAtom<formConfigType>({
  key: 'formConfig',
  default: () => initFormConfig(),
  deepEqualityCheck: true,
});
