import type { Ref } from 'react';

import { useKeyboardNavigation } from 'src/hooks/useKeyboardNavigation';
import { useListener } from 'src/hooks/useListener';

const Keys = { Up: 38, Down: 40, Enter: 13, Tab: 9, BackSpace: 8 };

export const useAutocompleteNavigation = <V>(
  inputRef: Ref<HTMLInputElement>,
  items: V[],
  values: V[],
  keyboardSelectionEnabled: boolean,
  backSpaceEnabled: boolean,
  onSelect: (item: V) => void,
  onRemove: (item: V) => void
) => {
  /** Handle selections with keys **/
  const keyboardPosition = useKeyboardNavigation(
    items.length,
    keyboardSelectionEnabled
  );

  const selectCurrentItem = () => {
    const currentItem = items[keyboardPosition.value];

    if (!currentItem) return;
    onSelect(currentItem);
  };

  function handleKeyDown(evt: Event) {
    if (evt instanceof KeyboardEvent) {
      switch (evt.keyCode || evt.key) {
        case Keys.Tab:
        case Keys.Enter:
        case 'Tab':
        case 'Enter':
          selectCurrentItem();
          evt.preventDefault();
          break;
      }
    }
  }

  useListener(inputRef, 'keydown', handleKeyDown, keyboardSelectionEnabled);

  /** Handle backspace removal **/
  function handleBackspace(evt: Event) {
    if (evt instanceof KeyboardEvent) {
      if (evt.keyCode === Keys.BackSpace || evt.key === 'Backspace') {
        onRemove(values[values.length - 1]);
      }
    }
  }

  useListener(inputRef, 'keydown', handleBackspace, backSpaceEnabled);

  return keyboardPosition;
};
