import { isFeatureEnabled } from 'src/config/features-toggle';

import type { Trans } from 'src/utils/i18nFormatters';

export const getMobileSearchPlaceholder = (trans: Trans) => {
  const isSearchMetroDisabled = isFeatureEnabled('SEARCH_NO_METRO');

  if (isSearchMetroDisabled) {
    return trans('insert_province_city_zones_without_metro');
  }

  return trans('insert_province_city_zones');
};

interface GetSearchPlaceholder {
  isCheckListEnabled: boolean;
  isGeographyDefined: boolean;
  isPlaceSearch: boolean;
  isInputFocused: boolean;
  isMetro: boolean;
  isMobile: boolean;
  isMetroListEnabled: boolean;
  trans: Trans;
}

export const getSearchPlaceholder = ({
  isCheckListEnabled,
  isGeographyDefined,
  isPlaceSearch,
  isInputFocused,
  isMetro,
  isMobile,
  isMetroListEnabled,
  trans,
}: GetSearchPlaceholder) => {
  if (
    isGeographyDefined &&
    !isInputFocused &&
    !isCheckListEnabled &&
    !isMetroListEnabled
  ) {
    return '';
  }

  if (isMobile) {
    return getMobileSearchPlaceholder(trans);
  }

  if (isPlaceSearch) {
    if (isMetro) {
      return trans('geography_search_metro_placeholder');
    }

    return trans('geography_search_locality_placeholder');
  }

  return trans('geography_search_new_search_placeholder');
};
