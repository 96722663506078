import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { useListener } from 'src/hooks/useListener';

import { geographyComponentStateAtom, geographySearchAtom } from '../../atoms';
import { isMetro } from '../../utils/entity';
import { getSearchPlaceholder } from '../../utils/placeholders';
import { usePlaceAutocompleteContext } from '../context';

import css from './styles.module.scss';

interface PlaceInputProps {
  placeholder?: string;
  customClass?: string;
  onClick?: () => void;
  onInput?: () => void;
}

export function Input({
  placeholder,
  customClass,
  onClick,
  onInput,
}: PlaceInputProps) {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);
  const { placeCheckListOpen } = useAtomValue(geographyComponentStateAtom);
  const { trans } = useTranslations();

  const {
    autocomplete: { autocomplete, value },
    error,
    hasFixedHeight,
    inputRef,
  } = usePlaceAutocompleteContext();

  const { enabled, inFocus } = autocomplete;

  useListener(inputRef, 'change', (evt) => {
    evt.stopPropagation();
  });

  const isCheckListEnabled =
    !maxScreenWidthSm && placeCheckListOpen && !enabled;

  const isMetroListEnabled =
    isCheckListEnabled && Boolean(value) && isMetro(value);

  return (
    <input
      className={clsx(
        'nd-autocomplete__input',
        css['in-placeInput'],
        error && css['is-error'],
        hasFixedHeight && css['has-fixedHeight'],
        customClass
      )}
      ref={inputRef}
      type="text"
      onClick={onClick}
      onInput={onInput}
      placeholder={
        placeholder ??
        getSearchPlaceholder({
          isCheckListEnabled,
          isMetroListEnabled,
          isGeographyDefined: Boolean(geographyData),
          isPlaceSearch: geographyData?.searchType === 'place',
          isInputFocused: inFocus,
          isMetro:
            geographyData?.searchType === 'place'
              ? isMetro(geographyData.value[0])
              : false,
          isMobile: maxScreenWidthSm,
          trans,
        })
      }
      data-hj-whitelist
      data-cy="geography-search"
    />
  );
}
