import type { PropsWithChildren, RefObject } from 'react';
import { createContext, useContext } from 'react';

import type {
  BaseEntityWithChildren,
  PlaceAutocompleteResponse,
} from './types';

interface PlaceAutocompleteContextValue {
  autocomplete: {
    autocomplete: PlaceAutocompleteResponse;
    value: BaseEntityWithChildren | null;
  };
  inputRef: RefObject<HTMLInputElement>;
  hasFixedHeight?: boolean;
  error?: boolean;
}

interface PlaceAutocompleteProviderProps
  extends PlaceAutocompleteContextValue,
    PropsWithChildren {}

export const PlaceAutocompleteContext =
  createContext<PlaceAutocompleteContextValue | null>(null);

export function PlaceAutocompleteProvider({
  autocomplete,
  error,
  inputRef,
  hasFixedHeight,
  children,
}: PlaceAutocompleteProviderProps) {
  return (
    <PlaceAutocompleteContext.Provider
      value={{ autocomplete, error, hasFixedHeight, inputRef }}
    >
      {children}
    </PlaceAutocompleteContext.Provider>
  );
}

export const usePlaceAutocompleteContext = () => {
  const context = useContext(PlaceAutocompleteContext);

  if (!context) {
    throw new Error(
      'usePlaceAutocompleteContext must be used within a PlaceAutocomplete component'
    );
  }

  return context;
};
