import { atom } from 'jotai';

export type SearchMethod =
  | 'PLACE'
  | 'AREA'
  | 'CIRCLE_DRAWING' // Deprecated
  | 'DISTANCE';

export const searchMethodAtom = atom<SearchMethod | undefined>(
  undefined as unknown as SearchMethod
);
