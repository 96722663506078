import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { useComponentControls } from './hooks/useComponentControls';

import type { GeographySearchFeatureToggles } from './types';

import { DesktopGeographySearch } from './DesktopGeographySearch';
import { FeaturesProvider } from './FeaturesProvider';
import { MobileGeographySearch } from './MobileGeographySearch';
import { SearchModal } from './SearchModal';

import css from './styles.module.scss';

interface ReactGeographySearchProps {
  featureToggles: GeographySearchFeatureToggles;
  error?: boolean;
  customClass?: string;
}

export function ReactGeographySearch({
  featureToggles,
  error = false,
  customClass,
}: ReactGeographySearchProps) {
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  return (
    <FeaturesProvider features={featureToggles}>
      <div className={clsx(css['in-geographySearch'], customClass)}>
        {maxScreenWidthSm ? (
          <MobileGeographySearch error={error} />
        ) : (
          <DesktopGeographySearch error={error} />
        )}
      </div>

      <SearchModal />
    </FeaturesProvider>
  );
}

// Useful for controlling this component from the outside
export const useGeographySearchControls = useComponentControls;

export type { GeographySearchFeatureToggles };
