import { dequal as deepEqual } from 'dequal/lite';

export { deepEqual };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeEmptyKeys<V extends Record<string, any>>(value: V) {
  const result: Partial<V> = {};

  for (const key in value) {
    if (
      (value[key] || value[key] === 0) &&
      !(Array.isArray(value[key]) && (value[key] as unknown[]).length === 0)
    ) {
      result[key] = value[key];
    }
  }

  return result;
}

type NoNilProperties<T> = {
  [P in keyof T]: Exclude<NoNilProperties<T[P]>, null | undefined>;
};

/**
 * Return a new object without the undefined and the null properties. This function is
 * not recursive.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const compactObject = <T extends Record<string, any>>(
  object: T
): NoNilProperties<T> => {
  const compactedObject = {} as NoNilProperties<T>;

  for (const key in object) {
    if (object[key] !== undefined && object[key] !== null) {
      compactedObject[key] = object[key];
    }
  }

  return compactedObject;
};

export function forceNumberValuesToString(value) {
  for (const key in value) {
    if (typeof value[key] === 'number') {
      value[key] = String(value[key]);
    }
  }

  return value;
}

/**
 * If the object is null if every value in the object is null.
 *
 * @param {object} obj
 * @returns boolean
 */
export function isObjectNull(obj?: Nullable<object>): boolean {
  if (!obj) return true;

  return Object.values(obj).every((v) => v === null || v === undefined);
}

/**
 * Returns true if all the object properties are empty.
 */
export const hasEmptyProperties = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, any | any[] | null | undefined>
) =>
  Object.values(obj).every((v) => {
    if (
      v === null ||
      v === undefined ||
      v === '' ||
      (v.constructor === Array && !v.length)
    ) {
      return true;
    }

    return false;
  });

/**
 * Evaluate if an object is a subset of another object.
 */
export const isSubset = (
  superObj: Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  subObj: Record<string, any> // eslint-disable-line @typescript-eslint/no-explicit-any
) => Object.keys(subObj).every((e) => subObj[e] === superObj[e]);

/**
 * Pick one or more keys from an object.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pick = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  ...keys: K[]
) =>
  Object.fromEntries(
    keys.filter((key) => key in obj).map((key) => [key, obj[key]])
  ) as Pick<T, K>;

/**
 * Returns a partial copy of an object omitting the keys specified.
 */
export const omit = <
  T extends Record<string, any>, // eslint-disable-line @typescript-eslint/no-explicit-any
  K extends Extract<keyof T, string>,
>(
  obj: T,
  ...keys: K[]
) => {
  const ret: Record<string, any> = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
  const excludeSet: Set<string> = new Set(keys);

  for (const key in obj) {
    if (!excludeSet.has(key)) {
      ret[key] = obj[key];
    }
  }

  return ret as Omit<T, K>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasTruthyProperties<T extends Record<string, any>>(
  target: T,
  propertiesToCheck: (keyof T)[]
) {
  const keysWithValue: (keyof T)[] = Object.entries(target)
    .filter(([_, value]) => Boolean(value))
    .map(([key]) => key);

  return propertiesToCheck.every((filter) => keysWithValue.includes(filter));
}
