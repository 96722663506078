import type { PropsWithChildren } from 'react';
import clsx from 'clsx';

import css from './styles.module.scss';

interface InputContainerProps extends PropsWithChildren {
  customClass?: string;
}

export function InputContainer({ children, customClass }: InputContainerProps) {
  return (
    <div
      className={clsx(
        css['in-placeInputContainer'],
        'nd-autocomplete__inputContainer',
        customClass
      )}
    >
      {children}
    </div>
  );
}
