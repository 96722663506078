import type { MutableRefObject } from 'react';

import { useIsomorphicLayoutEffect } from 'src/hooks/useIsomorphicLayoutEffect';

import { animateAccordion } from './animation';
import { AccordionJSClasses } from './index';

const animateMenu = (menu: Element, isClosing: boolean) => {
  const subMenu = menu.querySelector(`.${AccordionJSClasses.subMenu}`);

  if (isClosing) {
    menu.classList.add('is-open');
    subMenu && animateAccordion(subMenu as HTMLElement, true);
    menu.classList.remove('is-open');
  } else {
    subMenu && animateAccordion(subMenu as HTMLElement);
  }
};

export const useAnimateAccordion = (
  accordionState: boolean[] | null,
  prevAccordionState: boolean[] | null,
  accordionRef: MutableRefObject<HTMLElement | null>
) => {
  useIsomorphicLayoutEffect(() => {
    if (!prevAccordionState || !accordionState || !accordionRef.current) {
      return;
    }

    accordionState.forEach((itemState, menuIndex) => {
      if (itemState === prevAccordionState[menuIndex]) return;

      const allMenu = accordionRef.current?.querySelectorAll(
        `.${AccordionJSClasses.menu}`
      );

      const menu = allMenu && allMenu[menuIndex];

      if (menu) {
        animateMenu(menu, prevAccordionState[menuIndex]);
      }
    });
  }, [accordionState, accordionRef, prevAccordionState]);
};
