import clsx from 'clsx';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { geographySearchAtom } from '../atoms';

import { isPlaceSearchGeography } from 'src/libs/geography';
import { PepitaCheckbox } from 'src/libs/ui/pepita-checkbox';

import type { BaseEntity, GeographyValue } from 'src/types/geography';

import { isSameEntity } from '../utils/entity';

import css from './styles.module.scss';

export function PlaceCheckListAccordionContent({
  item,
  handleSelection,
}: {
  item: BaseEntity[];
  handleSelection: (item: GeographyValue, checked: boolean) => void;
}) {
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);

  const placeSearchGeography =
    geographyData && isPlaceSearchGeography(geographyData)
      ? geographyData
      : null;

  return (
    <ul className="nd-stack">
      {item.map((child, index) => (
        <li
          className={clsx(
            'nd-stackItem',
            css['in-checkListAccordionContent__item']
          )}
          key={index}
        >
          <PepitaCheckbox
            checked={placeSearchGeography?.value.some(
              (entity) =>
                isSameEntity(entity, child) ||
                isSameEntity(entity, child.parents[0]) // A child is selected if is in current selection or if its parent is selected
            )}
            onChange={(event) => {
              handleSelection(child, event.target.checked);
            }}
            customClass={css['in-checkListAccordionContent__checkbox']}
            controlCustomClass={
              css['in-checkListAccordionContent__controlCheckbox']
            }
          >
            {child.label}
          </PepitaCheckbox>
        </li>
      ))}
    </ul>
  );
}
