/**
 * On foreign countries we can name some geographical entities in different
 * ways
 */
export const COUNTRY_TO_LABELS: {
  [key: string]: { province: string; region: string };
} = {
  IT: {
    province: 'lbl_province', // i18n-extract-keys ["lbl_province"]
    region: 'lbl_region', // i18n-extract-keys ["lbl_region"]
  },
  FR: {
    province: 'lbl_department', // i18n-extract-keys ["lbl_department"]
    region: 'lbl_region', // i18n-extract-keys ["lbl_region"]
  },
  CH: {
    province: 'lbl_district', // i18n-extract-keys ["lbl_district"]
    region: 'lbl_canton', // i18n-extract-keys ["lbl_canton"]
  },
  SL: {
    province: 'lbl_province_zone', // i18n-extract-keys ["lbl_province_zone"]
    region: 'lbl_region', // i18n-extract-keys ["lbl_region"]
  },
  HR: {
    province: 'lbl_shire', // i18n-extract-keys ["lbl_shire"]
    region: 'lbl_region', // i18n-extract-keys ["lbl_region"]
  },
  MC: {
    province: 'lbl_principality', // i18n-extract-keys ["lbl_principality"]
    region: 'lbl_region', // i18n-extract-keys ["lbl_region"]
  },
  LU: {
    province: 'lbl_province_canton', // i18n-extract-keys ["lbl_province_canton"]
    region: 'lbl_region', // i18n-extract-keys ["lbl_region"]
  },
  DE: {
    province: 'lbl_district', // i18n-extract-keys ["lbl_district"]
    region: 'lbl_region', // i18n-extract-keys ["lbl_region"]
  },
};
