import type { SetStateAction, WritableAtom } from 'jotai';
import { atom } from 'jotai';

import { isFunction } from 'src/types/utils';

import { isProd } from 'src/utils/environment';
import { deepEqual } from 'src/utils/object';

const createdAtoms: string[] = [];

export const deepEqualAtom = <T>(
  debugLabel: string,
  atomValue: T
): WritableAtom<T, [value: SetStateAction<T>], void> => {
  if (createdAtoms.includes(debugLabel) && !isProd) {
    throw new Error(
      `deepEqualAtom with debugLabel ${debugLabel} already exists`
    );
  }

  createdAtoms.push(debugLabel);

  const deepEqualAtom = atom(
    atomValue,
    (get, set, value: SetStateAction<T> | T) => {
      const actualValue = get(deepEqualAtom);
      const newValue = isFunction(value) ? value(actualValue) : value;

      if (!deepEqual(actualValue, newValue)) {
        set(deepEqualAtom, newValue);
      }
    }
  );

  deepEqualAtom.debugLabel = debugLabel;

  return deepEqualAtom;
};
