import { useCallback } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useTrack } from 'src/tracking/hooks/useTrack';
import { EventName } from 'src/tracking/utils/enums';

export const useGeographySearchTracking = () => {
  const track = useTrack();
  const { user } = useAuthContext();

  const trackSearchbarZonesButtonPressed = useCallback(() => {
    if (isFeatureEnabled('SEGMENT_TRACKING')) {
      track(EventName.SEARCHBAR_ZONES_BUTTON_PRESSED, {
        'Is User Logged': Boolean(user),
        'User ID': user?.uuid,
      });
    }
  }, [track, user]);

  return {
    trackSearchbarZonesButtonPressed,
  };
};
