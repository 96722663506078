import { useTranslations } from '@pepita-react/i18n';
import { useSetAtom } from 'jotai';

import { comeFromSearchOptionsAtom } from '../atoms/comeFromSearchOptionsAtom';

import { getMobileSearchPlaceholder } from '../utils/placeholders';

import { OptionsItem } from './OptionsItem';

interface SearchByNameProps {
  onSelect: () => void;
}

export function SearchByName({ onSelect }: SearchByNameProps) {
  const { trans } = useTranslations();
  const setComeFromSearchOptions = useSetAtom(comeFromSearchOptionsAtom);

  return (
    <OptionsItem
      icon={{ name: 'option-text' }}
      label={trans('search_option_for_name_label')}
      description={getMobileSearchPlaceholder(trans)}
      onSelect={() => {
        setComeFromSearchOptions(true);
        onSelect();
      }}
    />
  );
}
