import type { GeographySearch } from '../types/geography';
import type {
  AreaSearchGeoJson,
  DistanceSearchCircle,
  DistanceSearchIsoline,
} from '../types/geojson';

import { swapLatLng } from './helpers';
import {
  isAreaSearchGeography,
  isDistanceSearchCircleGeography,
  isDistanceSearchIsolineGeography,
} from './validators';

export const getAreaSearchInitialValue = (
  geography: GeographySearch | null
): AreaSearchGeoJson | undefined => {
  if (geography && isAreaSearchGeography(geography)) {
    const { points } = geography.value;

    return {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [swapLatLng(points)],
      },
      properties: null,
    };
  }

  return undefined;
};

export const getDistanceSearchInitialValue = (
  geography: GeographySearch | null
) => {
  if (geography && isDistanceSearchIsolineGeography(geography)) {
    const { points, label, time, transport, address, center } = geography.value;

    const distanceSearchIsoline: DistanceSearchIsoline = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [swapLatLng(points)],
      },
      properties: {
        center,
        label,
        time,
        transport,
        address,
      },
    };

    return distanceSearchIsoline;
  }

  if (geography && isDistanceSearchCircleGeography(geography)) {
    const { center, radius } = geography.value;
    const [lat, lng] = center as number[]; // TODO: to be fixed, related to GeographySearch.value.center with searchType = 'circle'

    const distanceSearchCircle: DistanceSearchCircle = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [lng, lat],
      },
      properties: { radius },
    };

    return distanceSearchCircle;
  }

  return undefined;
};
