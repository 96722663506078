import { CATEGORIES, CONTRACT } from 'src/constants/typologies';

import type { rangeConfigType } from './formConfigTypes';
import { FormFields } from './formConfigTypes';

export const priceRangesConfiguration: rangeConfigType['range'] = [
  {
    min: 50000,
    max: 5000000,
    step: {
      0: 10000,
      100000: 20000,
      400000: 50000,
      1000000: 500000,
      2000000: 200000,
      3000000: 500000,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.VENDITA, CONTRACT.ASTE],
      [FormFields.CATEGORY]: [CATEGORIES.PALAZZI_EDIFICI],
    },
    min: 100000,
    max: 40000000,
    step: {
      100000: 100000,
      1000000: 200000,
      5000000: 500000,
      10000000: 1000000,
      20000000: 5000000,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.AFFITTO],
      [FormFields.CATEGORY]: [CATEGORIES.PALAZZI_EDIFICI],
    },
    min: 1000,
    max: 500000,
    step: {
      1000: 500,
      5000: 1000,
      10000: 5000,
      50000: 10000,
      100000: 20000,
      200000: 50000,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.VENDITA, CONTRACT.ASTE],
      [FormFields.CATEGORY]: [CATEGORIES.GARAGE_POSTI_AUTO],
    },
    min: 5000,
    max: 100000,
    step: {
      0: 5000,
      50000: 10000,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.AFFITTO],
      [FormFields.CATEGORY]: [CATEGORIES.GARAGE_POSTI_AUTO],
    },
    min: 100,
    max: 1000,
    step: {
      0: 10,
      300: 20,
      500: 50,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.VENDITA, CONTRACT.ASTE],
      [FormFields.CATEGORY]: [CATEGORIES.MAGAZZINI_DEPOSITI],
    },
    min: 10000,
    max: 1000000,
    step: {
      0: 5000,
      50000: 10000,
      100000: 20000,
      400000: 50000,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.AFFITTO],
      [FormFields.CATEGORY]: [CATEGORIES.MAGAZZINI_DEPOSITI],
    },
    min: 100,
    max: 10000,
    step: {
      0: 10,
      300: 20,
      500: 50,
      1000: 100,
      2000: 500,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.VENDITA, CONTRACT.ASTE],
      [FormFields.CATEGORY]: [CATEGORIES.CAPANNONI],
    },
    min: 10000,
    max: 10000000,
    step: {
      0: 5000,
      50000: 10000,
      100000: 20000,
      300000: 50000,
      500000: 100000,
      1000000: 500000,
      3000000: 1000000,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.AFFITTO],
      [FormFields.CATEGORY]: [CATEGORIES.CAPANNONI],
    },
    min: 300,
    max: 60000,
    step: {
      0: 100,
      1000: 200,
      3000: 500,
      10000: 5000,
      30000: 10000,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.VENDITA, CONTRACT.ASTE],
      [FormFields.CATEGORY]: [CATEGORIES.UFFICI_COWORKING],
    },
    min: 20000,
    max: 10000000,
    step: {
      0: 5000,
      50000: 10000,
      100000: 20000,
      300000: 50000,
      500000: 100000,
      1000000: 500000,
      3000000: 1000000,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.VENDITA, CONTRACT.ASTE],
      [FormFields.CATEGORY]: [CATEGORIES.NEGOZI_LOCALI_COMMERCIALI],
    },
    min: 10000,
    max: 10000000,
    step: {
      0: 5000,
      50000: 10000,
      100000: 20000,
      300000: 50000,
      500000: 100000,
      1000000: 500000,
      3000000: 1000000,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.AFFITTO],
      [FormFields.CATEGORY]: [
        CATEGORIES.UFFICI_COWORKING,
        CATEGORIES.NEGOZI_LOCALI_COMMERCIALI,
      ],
    },
    min: 100,
    max: 500000,
    step: {
      0: 100,
      1000: 200,
      3000: 500,
      10000: 5000,
      30000: 10000,
      100000: 20000,
      300000: 50000,
    },
  },
  {
    condition: {
      [FormFields.CATEGORY]: [CATEGORIES.STANZE],
    },
    min: 100,
    max: 1000,
    step: {
      0: 50,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.VENDITA, CONTRACT.ASTE],
      [FormFields.CATEGORY]: [CATEGORIES.TERRENI],
    },
    min: 1000,
    max: 10000000,
    step: {
      0: 100,
      2000: 500,
      5000: 5000,
      10000: 10000,
      100000: 50000,
      500000: 500000,
      1000000: 1000000,
    },
  },
  {
    condition: {
      [FormFields.CONTRACT]: [CONTRACT.AFFITTO],
      [FormFields.CATEGORY]: [CATEGORIES.TERRENI],
    },
    min: 100,
    max: 20000,
    step: {
      0: 50,
      1000: 100,
      2000: 500,
      5000: 5000,
      10000: 10000,
    },
  },
  {
    condition: {
      [FormFields.CATEGORY]: [CATEGORIES.NUOVE_COSTRUZIONI],
    },
    min: 10000,
    max: 5000000,
    step: {
      0: 10000,
      100000: 20000,
      400000: 50000,
      1000000: 100000,
      2000000: 200000,
      3000000: 500000,
    },
  },
  {
    condition: { [FormFields.CONTRACT]: [CONTRACT.AFFITTO] },
    min: 200,
    max: 8000,
    step: {
      0: 50,
      1000: 100,
      2000: 500,
    },
  },
];
