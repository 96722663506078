import type { Ref } from 'react';
import { useRef } from 'react';

export const id = <T>(v: T) => v;

export const useConstValue = <T>(value: T, warning: string) => {
  const ref = useRef(value);

  if (ref.current !== value) {
    // eslint-disable-next-line no-console
    console.trace(warning);
  }

  return ref.current;
};

export const useMutableRef = <T>(value: T) => {
  const ref = useRef(value);

  ref.current = value;

  return ref;
};

export const refElementInterop = <T>(value: Ref<T> | T): T => {
  if (value && typeof value === 'object' && 'current' in value) {
    return value.current as T;
  }

  return value as T;
};
