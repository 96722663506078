import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

const ref: { current: Document | null } = {
  current: null,
};

export function useDocument() {
  useIsomorphicLayoutEffect(() => {
    ref.current = document;
  }, []);

  return ref;
}
