import { useCallback } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import type { Language } from 'src/types/translations';

import {
  currencyFormatter,
  metersFormatter,
  numberFormatter,
  squareMetersFormatter,
} from 'src/utils/i18nFormatters';

export const useNumberFormatterCallback = () => {
  const { lang } = useTranslations();

  return useCallback(
    (value: number, decimals?: number) =>
      numberFormatter(lang as Language, value, decimals),
    [lang]
  );
};

export const useCurrencyFormatterCallback = () => {
  const { lang, trans } = useTranslations();

  return useCallback(
    (
      value: number,
      currency: string,
      currencyPosition?: 'before' | 'after',
      decimals?: number,
      monthly?: boolean,
      perBed?: boolean
    ) =>
      currencyFormatter(
        trans,
        lang as Language,
        value,
        currency,
        currencyPosition,
        decimals,
        monthly,
        perBed
      ),
    [trans, lang]
  );
};

export const useSquareMetersFormatterCallback = () => {
  const { lang } = useTranslations();

  return useCallback(
    (meters: number, decimals?: number): string =>
      squareMetersFormatter(lang as Language, meters, decimals),
    [lang]
  );
};

export const useMetersFormatterCallback = () => {
  const { lang } = useTranslations();

  return useCallback(
    (meters: number, decimals?: number): string =>
      metersFormatter(lang as Language, meters, decimals),
    [lang]
  );
};
