import type { PropsWithChildren } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { geographySearchAtom } from '../atoms';

import { CurrentLocation } from './CurrentLocation';
import { DistanceFromAPoint } from './DistanceFromAPoint';
import { DrawAreaOnMap } from './DrawAreaOnMap';
import { SearchByName } from './SearchByName';
import { SelectOnMap } from './SelectOnMap';

import css from './styles.module.scss';

interface SearchOptionsProps extends PropsWithChildren {}

function SearchOptionsRoot({ children }: SearchOptionsProps) {
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  // TODO: SearchOptions Keyboard Navigation

  return (
    <div
      data-cy="search-options-list-container"
      className={clsx(
        !maxScreenWidthSm && 'nd-autocomplete__list',
        'is-open',
        css['in-searchOptions']
      )}
    >
      {geographyData && <CurrentLocation />}

      <ul className="nd-stack" data-cy="search-options-list">
        {children}
      </ul>
    </div>
  );
}

export const SearchOptions = Object.assign(SearchOptionsRoot, {
  SearchByName,
  SelectOnMap,
  DrawAreaOnMap,
  DistanceFromAPoint,
});
