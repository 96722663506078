import { useRef } from 'react';
import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { geographyComponentStateAtom } from '../atoms';

import { useClickOutside } from 'src/hooks/useClickOutside';
import { useComponentControls } from '../hooks/useComponentControls';

import { geographySearchAtom } from '../../GeographySearch';
import { GeographyTags } from '../GeographyTags';
import { PlaceAutocomplete } from '../PlaceAutocomplete';
import { PlaceCheckList } from '../PlaceCheckList';
import { SearchOptions } from '../SearchOptions';

interface DesktopGeographySearchProps {
  error: boolean;
}

export function DesktopGeographySearch({
  error = false,
}: DesktopGeographySearchProps) {
  const { closeSearchOptions, openSearchOptions } = useComponentControls();
  const placeAutocompleteRef = useRef<HTMLDivElement>(null);
  const { placeCheckListOpen, searchOptionsOpen } = useAtomValue(
    geographyComponentStateAtom
  );
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);

  useClickOutside(placeAutocompleteRef, closeSearchOptions, searchOptionsOpen);

  return (
    <>
      <PlaceAutocomplete ref={placeAutocompleteRef} error={error}>
        <PlaceAutocomplete.InputContainer>
          {geographyData ? <GeographyTags /> : <PlaceAutocomplete.Icon />}

          <PlaceAutocomplete.Input
            onClick={openSearchOptions}
            onInput={closeSearchOptions}
          />
        </PlaceAutocomplete.InputContainer>

        <PlaceAutocomplete.SuggestionList />

        {placeCheckListOpen && <PlaceCheckList />}
      </PlaceAutocomplete>

      {searchOptionsOpen && (
        <SearchOptions>
          <SearchOptions.SelectOnMap />
          <SearchOptions.DrawAreaOnMap />
          <SearchOptions.DistanceFromAPoint />
        </SearchOptions>
      )}
    </>
  );
}
