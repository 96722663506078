import area from '@turf/area';
import { polygon } from '@turf/helpers';

export const swapLatLng = (points: number[][]) =>
  points.map(([lat, lng]) => [lng, lat]);

/**
 * @returns The area in square meters.
 */
export const calculateCircleArea = (radius: number) => {
  return Math.PI * Math.pow(radius, 2);
};

/**
 *
 * @param {number[][]} points - Either an array of [longitude, latitude] points representing a polygon
 * @returns The area in square meters.
 */
export const calculatePolygonArea = (points: number[][]) => {
  const polygonFeature = polygon([points]);

  return area(polygonFeature);
};
