export const adminCentres = [
  10827, // Agrigento
  8987, // Alessandria
  8604, // Ancona
  10198, // Andria
  11937, // Aosta
  11557, // Arezzo
  8658, // Ascoli Piceno
  9179, // Asti
  5341, // Avellino
  10199, // Bari
  10200, // Barletta
  12014, // Belluno
  5460, // Benevento
  7081, // Bergamo
  9298, // Biella
  5890, // Bologna
  11224, // Bolzano
  7329, // Brescia
  10242, // Brindisi
  10459, // Cagliari
  10873, // Caltanissetta
  8854, // Campobasso
  10462, // Carbonia
  5553, // Caserta
  10906, // Catania
  5092, // Catanzaro
  4617, // Chieti
  7559, // Como
  4969, // Cosenza
  7705, // Cremona
  5169, // Crotone
  9455, // Cuneo
  10958, // Enna
  8670, // Fermo
  5982, // Ferrara
  11609, // Firenze
  10285, // Foggia
  5953, // Forlì
  6482, // Frosinone
  6846, // Genova
  6232, // Gorizia
  11649, // Grosseto
  10485, // Iglesias
  6919, // Imperia
  8955, // Isernia
  4537, // La Spezia
  6970, // L'Aquila
  10596, // Lanusei
  6546, // Latina
  10361, // Lecce
  7826, // Lecco
  11675, // Livorno
  7905, // Lodi
  11703, // Lucca
  8747, // Macerata
  8153, // Mantova
  11731, // Massa
  4807, // Matera
  11018, // Messina
  8042, // Milano
  6023, // Modena
  8044, // Monza
  5685, // Napoli
  9685, // Novara
  10611, // Nuoro
  10789, // Olbia
  10698, // Oristano
  12137, // Padova
  11131, // Palermo
  6122, // Parma
  8303, // Pavia
  11881, // Perugia
  8825, // Pesaro
  4728, // Pescara
  6079, // Piacenza
  11764, // Pisa
  11799, // Pistoia
  6282, // Pordenone
  4888, // Potenza
  11782, // Prato
  11168, // Ragusa
  6156, // Ravenna
  5249, // Reggio Calabria
  6192, // Reggio Emilia
  6627, // Rieti
  6219, // Rimini
  6737, // Roma
  12223, // Rovigo
  5843, // Salerno
  10516, // Sanluri
  10807, // Sassari
  7043, // Savona
  11838, // Siena
  11190, // Siracusa
  8444, // Sondrio
  10449, // Taranto
  10815, // Tempio Pausania
  4788, // Teramo
  11934, // Terni
  9987, // Torino
  10653, // Tortolì
  10238, // Trani
  11214, // Trapani
  11534, // Trento
  12319, // Treviso
  6307, // Trieste
  6437, // Udine
  8848, // Urbino
  8595, // Varese
  12370, // Venezia
  10102, // Verbania
  10190, // Vercelli
  12583, // Verona
  5330, // Vibo Valentia
  12486, // Villacidro
  10549, // Vicenza
  6820, // Viterbo
];
