import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import { clsx } from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

export function NoResultsItem() {
  const { trans } = useTranslations();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  return (
    <li className={clsx('nd-stackItem', 'nd-autocomplete__listItem')}>
      {maxScreenWidthSm && (
        <span className="nd-stackItem__graphic">
          <PepitaIcon name="exclamation-mark-circle" />
        </span>
      )}
      <span className="nd-stackItem__text">
        {trans('autocomplete_no_results')}
      </span>
    </li>
  );
}
