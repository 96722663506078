import clsx from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { usePlaceAutocompleteContext } from '../context';

import css from './styles.module.scss';

interface IconProps {
  hasLeftSpace?: boolean;
  showBackIcon?: boolean;
}

export function Icon({
  hasLeftSpace = false,
  showBackIcon = false,
}: IconProps) {
  const { error, autocomplete } = usePlaceAutocompleteContext();

  const { inFocus, search } = autocomplete.autocomplete;

  const commonCustomClass = clsx(
    css['in-placeIcon'],
    hasLeftSpace && css['has-leftSpace']
  );

  if (showBackIcon && inFocus && Boolean(search)) {
    return <PepitaIcon name="arrow-left" customClass={commonCustomClass} />;
  }

  return (
    <PepitaIcon
      name="lens"
      nameAlternate="exclamation-mark-circle--active"
      isAlternate={error}
      customClass={clsx(
        'js-geographySearch__lens',
        commonCustomClass,
        error && css['has-error']
      )}
    />
  );
}
