import { isCity, isProvince } from 'src/libs/geography';

import type {
  BaseEntity,
  EntityWithOriginalLabel,
  EntryLabel,
  GeographyValue,
} from 'src/types/geography';
import { ENTITY_TYPE } from 'src/types/geography';

import { COUNTRY_TO_LABELS } from 'src/utils/countryLabels';
import type { Trans } from 'src/utils/i18nFormatters';
import { capitalize } from 'src/utils/string';

import {
  areSameMetro,
  getEntityParentByType,
  getGeographyEntityID,
  isCountry,
  isMetro,
  isMicrozone,
  isRegion,
  isZone,
} from './entity';

const computeProvincePerNation = (selection: GeographyValue) => {
  const countryID = getGeographyEntityID(selection, ENTITY_TYPE.country);

  return (
    (countryID && COUNTRY_TO_LABELS[countryID]?.province) || 'lbl_province'
  );
};

const computeRegionPerNation = (selection: GeographyValue) => {
  const countryID = getGeographyEntityID(selection, ENTITY_TYPE.country);

  return (countryID && COUNTRY_TO_LABELS[countryID]?.region) || 'lbl_region';
};

const getMetroLines = (selection: GeographyValue) => {
  if (selection.type === ENTITY_TYPE.metro) {
    if (selection.sameStationOn) {
      return [
        getEntityParentByType(selection, ENTITY_TYPE.metroLine)?.label,
        ...selection.sameStationOn.map((line) => line.label),
      ];
    }

    return [getEntityParentByType(selection, ENTITY_TYPE.metroLine)?.label];
  }

  return null;
};

/** Given place data, it returns a tuple {label, description} which can be
 * used by the autcomplete to format the label
 * Some examples:
 *  - Roma => {label: Roma, description: Comune}
 *  - Re di Roma => {label: Metro Re di Roma - Metro A, description: Roma}
 * @param entries all place data
 * @param labelsForCountry feature toggle for enabling personalized geography labels for country
 * @param trans a trans function to build localized contents
 */
export const computeEntryLabel = (
  entries: BaseEntity[],
  labelsForCountry: boolean,
  trans: Trans
): EntryLabel => {
  const selection: GeographyValue = entries[0];

  if (!selection) {
    // Security check for corrupted responses
    return { label: '', description: '' };
  }

  const isMultiSelection = entries.length > 1;
  const withParents = selection.parents && selection.parents.length > 0;

  const metroLines = getMetroLines(selection);

  let label = metroLines
    ? `${trans('lbl_metro')} ${
        selection.originalLabel ? selection.originalLabel : selection.label
      } - ${metroLines.join(', ')}`
    : selection.label;

  const parentCity =
    selection.parents &&
    selection.parents.find((parent) => parent.type === ENTITY_TYPE.city);

  // multi selection with zones
  if (isMultiSelection && parentCity) {
    label = parentCity.label;
  }

  let description = '';

  if (isProvince(selection)) {
    description = labelsForCountry
      ? // i18n-extract-disable-next-line
        trans(computeProvincePerNation(selection))
      : trans('lbl_province', { count: 1 });
  } else if (selection.admin_centre) {
    // administrative center (capoluogo)
    description = trans('lbl_city', { count: 1 });
  } else if (isRegion(selection)) {
    description = labelsForCountry
      ? // i18n-extract-disable-next-line
        trans(computeRegionPerNation(selection))
      : trans('lbl_region');
  } else if (isCountry(selection)) {
    description = capitalize(trans('lbl_country'));
  } else if (isMultiSelection && withParents) {
    if (isMetro(selection)) {
      description = computeMetroDescription(entries, trans);
    } else {
      // i18n-extract-mark-context-next-line ["1", "2", "3", "4"]
      description = trans(`polygon_selected_type`, {
        params: [entries.length],
        count: entries.length,
        context: `${
          selection.type === ENTITY_TYPE.microzone
            ? ENTITY_TYPE.cityZone
            : selection.type
        }`,
      });
    }
  } else {
    let parent = '';

    if (withParents) {
      if (parentCity) {
        parent = parentCity.label || '';
      } else {
        parent = selection.parents[0].label;
      }
    }

    // i18n-extract-mark-context-next-line ["1", "2", "3", "4", "6", "8", "9"]
    description = trans(`geo_entity_type`, {
      context: `${selection.type}`,
    }).replace('%parent%', parent); // TODO workaround for old trans, we need to rewrite it!
  }

  return {
    label,
    description,
  };
};

/**
 * Returns the description for a list of metros and metro lines
 * e.g 2 linee metro or 25 stazioni metro
 * @param entries
 * @param trans
 */
const computeMetroDescription = (
  entries: GeographyValue[],
  trans: Trans
): string => {
  switch (true) {
    case entries.every((entry) => entry.type === ENTITY_TYPE.metroLine):
      return trans('lbl_metro_lines', { params: [entries.length] });
    default:
      return trans('lbl_metro_stations', {
        params: [
          entries.reduce(
            (acc, entry) =>
              entry.type === ENTITY_TYPE.metroLine
                ? acc + (entry.children ? entry.children.length : 0)
                : acc + 1,
            0
          ),
        ],
      });
  }
};

/**
 * Get an array of selected metro and metro lines and filter out
 * duplicated entries (stations with the same label)
 *
 * @param entries
 */
export const filterMetroWithSameLabel = (
  entries: GeographyValue[]
): GeographyValue[] => {
  const filteredEntries: GeographyValue[] = [];

  entries.forEach((entry) => {
    if (entry.type === ENTITY_TYPE.metro) {
      const duplicatedEntry = entries.find((e) => {
        let res;

        if (e.type === ENTITY_TYPE.metroLine) {
          // For every metro line selected, I need to check children
          res = Boolean(
            e.children?.find((child) =>
              areSameMetro(child as GeographyValue, entry)
            )
          );
        } else {
          // Checking if I already have a duplicate for this entry
          res = filteredEntries.find((e) => areSameMetro(e, entry));
        }

        return res;
      });

      if (!duplicatedEntry) {
        filteredEntries.push(entry);
      }
    } else {
      // Metro lines can be added without problems
      filteredEntries.push(entry);
    }
  });

  return filteredEntries;
};

const formatTagLabel = ({ label, description }: EntryLabel) =>
  `${label} • ${description}`;

/**
 * Return the string to show inside the result tag in case of single entity selected
 * @param entity
 * @param labelsForCountry
 * @param trans
 */
const computeTagLabel = (
  entity: BaseEntity,
  labelsForCountry,
  trans: Trans
): string => {
  if (
    isProvince(entity) ||
    isCity(entity) ||
    isZone(entity) ||
    isMicrozone(entity) ||
    isRegion(entity) ||
    isCountry(entity)
  ) {
    return formatTagLabel(computeEntryLabel([entity], labelsForCountry, trans));
  }

  if (isMetro(entity)) {
    return formatTagMetro(entity, trans);
  }

  return entity.label;
};

/**
 * Get a metro or a metro station entry and returns a string with the description
 * e.g <Metro Marconi> for ENTITY_TYPE.metro and <Metro A • Roma> for ENTITY_TYPE.metroLine
 * @param entity
 * @param trans
 */
const formatTagMetro = (
  entity: EntityWithOriginalLabel,
  trans: Trans
): string => {
  if (entity.type === ENTITY_TYPE.metro) {
    const city = getEntityParentByType(entity, ENTITY_TYPE.city);

    return formatTagLabel({
      label: `${trans('lbl_metro')} ${
        entity.originalLabel ? entity.originalLabel : entity.label
      }`,
      description: city ? city.label : '',
    });
  }

  return formatTagLabel({
    label: entity.parents[0].label,
    description: entity.label,
  });
};

/**
 * Return the label used for the result tag in case of places (e.g. Roma • Comune)
 * @param values
 * @param labelsForCountry
 * @param trans
 */
export const getGeographyTagLabel = (
  values: GeographyValue[],
  labelsForCountry: boolean,
  trans: Trans
) => {
  if (
    values.every(
      (value) =>
        value.type === ENTITY_TYPE.metro || value.type === ENTITY_TYPE.metroLine
    )
  ) {
    values = filterMetroWithSameLabel(values);
  }

  if (values.length === 1) {
    return computeTagLabel(values[0], labelsForCountry, trans);
  }

  return formatTagLabel(computeEntryLabel(values, labelsForCountry, trans));
};
