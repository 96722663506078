import { useRef } from 'react';
import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { geographyComponentStateAtom } from '../atoms';

import { useComponentControls } from '../hooks/useComponentControls';

import { geographySearchAtom } from '../../GeographySearch';
import { GeographyTags } from '../GeographyTags';
import { PlaceAutocomplete } from '../PlaceAutocomplete';
import { SearchOptions } from '../SearchOptions';
import { SearchOptionModal } from '../SearchOptionsModal';

import css from './styles.module.scss';

interface MobileGeographySearchProps {
  error: boolean;
}

export function MobileGeographySearch({
  error = false,
}: MobileGeographySearchProps) {
  const autocompleteInputRef = useRef<HTMLInputElement>(null);
  const { closeSearchOptionsModal, openSearchOptionsModal, openSearchModal } =
    useComponentControls();
  const { searchOptionsModalOpen } = useAtomValue(geographyComponentStateAtom);
  const geographyData = useAtomicStateAtomValue(geographySearchAtom);

  const handleSearchByNameSelect = () => {
    if (geographyData) {
      openSearchModal('PLACE');
    } else {
      autocompleteInputRef.current?.focus();
    }
  };

  return (
    <>
      <PlaceAutocomplete error={error}>
        <PlaceAutocomplete.InputContainer>
          {geographyData ? <GeographyTags /> : <PlaceAutocomplete.Icon />}

          <PlaceAutocomplete.Input onClick={openSearchOptionsModal} />
        </PlaceAutocomplete.InputContainer>
      </PlaceAutocomplete>

      {searchOptionsModalOpen && (
        <SearchOptionModal onClose={closeSearchOptionsModal}>
          <PlaceAutocomplete
            customClass={css['in-mobileGeographySearch__autocomplete']}
            inputRef={autocompleteInputRef}
            hasFixedHeight
          >
            <PlaceAutocomplete.InputContainer
              customClass={css['in-mobileGeographySearch__inputContainer']}
            >
              {geographyData ? (
                <GeographyTags hasFixedHeight />
              ) : (
                <>
                  <PlaceAutocomplete.Icon hasLeftSpace showBackIcon />

                  <PlaceAutocomplete.Input />
                </>
              )}
            </PlaceAutocomplete.InputContainer>

            <PlaceAutocomplete.SuggestionList />
          </PlaceAutocomplete>

          <SearchOptions>
            <SearchOptions.SearchByName onSelect={handleSearchByNameSelect} />
            <SearchOptions.DrawAreaOnMap />
            <SearchOptions.DistanceFromAPoint />
          </SearchOptions>
        </SearchOptionModal>
      )}
    </>
  );
}
