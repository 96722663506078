import type { Ref } from 'react';
import { useState } from 'react';

import { useListener } from 'src/hooks/useListener';

export const useAutocompleteSearch = (input: Ref<HTMLInputElement>) => {
  const [search, setSearch] = useState('');
  const [inFocus, setIsFocus] = useState(false);

  function handleFocus() {
    setIsFocus(true);
    setSearch('');
  }

  function handleBlur() {
    setIsFocus(false);
  }

  function handleInput(evt) {
    setSearch(evt.target.value);
  }

  useListener(input, 'focus', handleFocus);
  useListener(input, 'blur', handleBlur);
  useListener(input, 'input', handleInput);

  return {
    search,
    inFocus,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onInput: handleInput,
  };
};
