import type { LatLngLiteral } from '@pepita-canary/leaflet';
import { atom } from 'jotai';

import { createAtomicStateStateAtom } from 'src/atoms/atomic-state';

export type MapBounds = {
  minLat: number;
  maxLat: number;
  minLng: number;
  maxLng: number;
};

export const mapBoundsAtom = createAtomicStateStateAtom<Optional<MapBounds>>({
  key: `MapBounds`,
  default: undefined,
});

export const mapZoomAtom = createAtomicStateStateAtom<number | undefined>({
  key: `MapZoom`,
  default: undefined,
});

export const mapCenterAtom = atom<LatLngLiteral | undefined>(
  undefined as unknown as LatLngLiteral
);
