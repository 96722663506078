import { useCallback, useEffect, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';
import { useAtom } from 'jotai';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';
import { geographySearchAtom, tempGeographySearchAtom } from '../atoms';

import { useComponentControls } from '../hooks/useComponentControls';
import { usePolygonNamingApi } from '../hooks/usePolygonNamingApi';

import {
  isDistanceSearchGeography,
  isDistanceSearchIsolineGeography,
  isPlaceSearchGeography,
} from 'src/libs/geography';
import { PepitaTag } from 'src/libs/ui/pepita-tag';

import { getDrawingTagLabel } from '../utils/addresses';
import { isValidPlaceChecklistSelection } from '../utils/entity';
import { getGeographyTagLabel } from '../utils/labels';

import { useIsGeographyFeatureEnabled } from '../FeaturesProvider';

import css from './styles.module.scss';

interface GeographyTagsProps {
  hasFixedHeight?: boolean;
  shouldUseTempGeography?: boolean;
  customClass?: string;
  onRemove?: () => void;
}

export function GeographyTags({
  hasFixedHeight = false,
  shouldUseTempGeography = false,
  customClass,
  onRemove,
}: GeographyTagsProps) {
  const { trans } = useTranslations();
  const [geographySearch, setGeographySearch] =
    useAtomicStateStateAtom(geographySearchAtom);
  const [tempGeographySearch, setTempGeographySearch] = useAtom(
    tempGeographySearchAtom
  );
  const [label, setLabel] = useState<Nullable<string>>();

  const selectedGeographySearch = shouldUseTempGeography
    ? tempGeographySearch
    : geographySearch;

  const setSelectedGeographySearch = shouldUseTempGeography
    ? setTempGeographySearch
    : setGeographySearch;

  const { openSearchModal, openPlaceCheckList, closeSearchOptions } =
    useComponentControls();
  const isGeographyFeatureEnabled = useIsGeographyFeatureEnabled();

  /** Effect for setting drawings label **/
  const polygonLabel = usePolygonNamingApi(selectedGeographySearch);

  useEffect(() => {
    if (!selectedGeographySearch) {
      setLabel(null);

      return;
    }

    // Place search
    if (selectedGeographySearch.searchType === 'place') {
      setLabel(
        getGeographyTagLabel(
          selectedGeographySearch.value,
          isGeographyFeatureEnabled('labelsForCountry'),
          trans
        )
      );

      return;
    }

    // Distance search isoline
    if (isDistanceSearchIsolineGeography(selectedGeographySearch)) {
      setLabel(selectedGeographySearch.value.label);

      return;
    }

    // Distance search circle and Area search
    setLabel(getDrawingTagLabel(selectedGeographySearch, trans, polygonLabel));
  }, [selectedGeographySearch, isGeographyFeatureEnabled, polygonLabel, trans]);

  const handleClick = useCallback(() => {
    if (!selectedGeographySearch) return;

    closeSearchOptions();

    // Place search
    if (
      isPlaceSearchGeography(selectedGeographySearch) &&
      isValidPlaceChecklistSelection(selectedGeographySearch)
    ) {
      openPlaceCheckList();

      return;
    }

    // Distance search
    if (isDistanceSearchGeography(selectedGeographySearch)) {
      openSearchModal('DISTANCE');

      return;
    }

    // Area search
    openSearchModal('AREA');
  }, [
    selectedGeographySearch,
    closeSearchOptions,
    openPlaceCheckList,
    openSearchModal,
  ]);

  return label ? (
    <div
      className={clsx(
        css['in-geographyTags'],
        hasFixedHeight && css['has-fixedHeight'],
        customClass
      )}
    >
      <PepitaTag
        onClick={handleClick}
        onRemove={() => {
          if (onRemove) {
            onRemove();
          }

          setSelectedGeographySearch(null);
        }}
        customClass={css['in-geographyTags__tag']}
      >
        {label}
      </PepitaTag>
    </div>
  ) : null;
}
