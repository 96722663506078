import { memo, useCallback, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';
import { geographySearchAtom } from '../atoms';

import { Accordion } from 'src/components/Accordion';

import { isPlaceSearchGeography } from 'src/libs/geography';

import type {
  BaseEntityWithChildren,
  GeographyValue,
} from 'src/types/geography';
import { ENTITY_TYPE } from 'src/types/geography';

import { isSameEntity } from '../utils/entity';
import {
  handleLeafSelection,
  handleParentSelection,
} from '../utils/selections';

import { PlaceCheckListAccordionContent } from '../PlaceCheckListAccordionContent';
import { PlaceCheckListAccordionText } from '../PlaceCheckListAccordionText';

import css from './styles.module.scss';

interface PlaceCheckListAccordionProps {
  checklistData: BaseEntityWithChildren[];
  city: BaseEntityWithChildren;
}

// Added memo function to prevent re-rendering of the entire component upon checking items
export const PlaceCheckListAccordion = memo(
  ({ checklistData, city }: PlaceCheckListAccordionProps) => {
    const { trans } = useTranslations();
    const [geographyData, setGeographyData] =
      useAtomicStateStateAtom(geographySearchAtom);

    const placeSearchGeography =
      geographyData && isPlaceSearchGeography(geographyData)
        ? geographyData
        : null;

    const checkListCity = {
      ...city,
      label: trans('lbl_whole_city'),
    };

    const accordionItems = [checkListCity, ...checklistData];

    const [openedAccordionItems, setOpenedAccordionItems] = useState(() => {
      if (!placeSearchGeography) return [-1];

      const openedItemIndex = accordionItems.findIndex(
        (i) =>
          i.children?.some((c) =>
            isSameEntity(placeSearchGeography.value[0], c)
          )
      );

      return [openedItemIndex];
    });

    const handleSelection = useCallback(
      (item: GeographyValue, checked: boolean) => {
        setGeographyData((oldState) => {
          if (!oldState || !isPlaceSearchGeography(oldState)) return oldState;

          const currentSelection =
            oldState.value[0]?.type === ENTITY_TYPE.city
              ? []
              : [...oldState.value];

          let newValues: GeographyValue[];

          if (item.type === ENTITY_TYPE.city) {
            // When we select the entire city we remove all other children
            newValues = [city];
          } else if (
            item.type === ENTITY_TYPE.microzone ||
            item.type === ENTITY_TYPE.metro
          ) {
            newValues = handleLeafSelection(
              item,
              checked,
              city,
              currentSelection
            );
          } else {
            newValues = handleParentSelection(
              item,
              checked,
              city,
              currentSelection
            );
          }

          return {
            searchType: oldState.searchType,
            value: newValues.length > 0 ? newValues : [city],
          };
        });
      },
      [city, setGeographyData]
    );

    return (
      <Accordion
        customClass={clsx(
          'nd-autocomplete__listContent',
          css['in-checkListAccordion']
        )}
        open={openedAccordionItems}
        onChange={(selectedItem) => {
          setOpenedAccordionItems((prevState) =>
            prevState.includes(selectedItem)
              ? prevState.filter((item) => item !== selectedItem)
              : prevState.concat(selectedItem)
          );
        }}
        items={accordionItems.map((item) => ({
          text: (
            <PlaceCheckListAccordionText
              item={item}
              handleSelection={handleSelection}
            />
          ),
          content:
            item.type !== ENTITY_TYPE.city && item.children ? (
              <PlaceCheckListAccordionContent
                item={item.children}
                handleSelection={handleSelection}
              />
            ) : null,
          labelCustomClass: css['in-checkListAccordion__label'],
        }))}
      />
    );
  }
);

PlaceCheckListAccordion.displayName = 'PlaceCheckListAccordion';
