import { type RefObject, useEffect, useMemo } from 'react';
import type { LatLngLiteral } from '@pepita-canary/leaflet';
import { useTranslations } from '@pepita-react/i18n';

import { useAtomicStateStateAtom } from 'src/atoms/atomic-state';
import { geographySearchAtom } from '../atoms';

import { useAutocomplete } from 'src/components/Autocomplete';

import { useComponentControls } from '../hooks/useComponentControls';

import type { BaseEntityWithChildren } from './types';

import { autocompleteApi } from '../utils/autocompleteApi';
import { isValidPlaceChecklistSelection } from '../utils/entity';
import { placeDataToGeography } from '../utils/geography';

import { useIsGeographyFeatureEnabled } from '../FeaturesProvider';

const defaultCenter = {
  lat: 41.902715,
  lng: 12.496245,
};

interface UsePlaceAutocompleteArgs {
  inputRef: RefObject<HTMLInputElement>;
}

export const usePlaceAutocomplete = ({
  inputRef,
}: UsePlaceAutocompleteArgs) => {
  const { trans } = useTranslations();
  const { openPlaceCheckList } = useComponentControls();
  const [geographyData, setGeographyData] =
    useAtomicStateStateAtom(geographySearchAtom);

  // We get the center of the last one place selected or a default value
  const autocompleteProximity: LatLngLiteral = useMemo(() => {
    return geographyData &&
      geographyData.searchType === 'place' &&
      geographyData.value[0]?.center
      ? geographyData.value[0].center
      : defaultCenter;
  }, [geographyData]);

  const isGeographyFeatureEnabled = useIsGeographyFeatureEnabled();

  const autocomplete = useAutocomplete<BaseEntityWithChildren>({
    input: inputRef,
    minLength: 2,
    autocompleteApi: (query) =>
      autocompleteApi(
        autocompleteProximity,
        query,
        trans,
        isGeographyFeatureEnabled
      ),
    withBackspace: Boolean(geographyData),
    reducer: (_, action) => {
      switch (action.type) {
        case 'ADD_ITEM':
          return [action.item];
      }

      return action.changes || [];
    },
  });

  const { values, reset, inFocus, search } = autocomplete;

  const value = values.length > 0 ? values[0] : null;

  // Get text to show inside the input...
  // If it is in focus we show the string as typed by the user
  // Otherwise we show an empty string (in case of selection we already
  // have the geography tag to show)
  const inputText = inFocus ? search : '';

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.value = inputText;
  }, [inputRef, inputText]);

  /*
    When a value is selected by the autocomplete we need to:
    - update the geographyData atom with the new selected value;
    - open the place checklist if the geographyData matches some conditions.
  */
  useEffect(() => {
    const geographyPlaceData = value ? placeDataToGeography([value]) : null;

    setGeographyData(geographyPlaceData);

    if (
      geographyPlaceData &&
      isValidPlaceChecklistSelection(geographyPlaceData)
    ) {
      openPlaceCheckList();
    }
  }, [openPlaceCheckList, setGeographyData, value]);

  /*
    When the geographyData is set to null (the user has removed the autocomplete
    tag), we need to reset the autocomplete value to null.
  */
  useEffect(() => {
    if (!geographyData) {
      reset();
    }
  }, [geographyData, reset]);

  return { autocomplete, value };
};
