import type { Ref } from 'react';
import { useEffect } from 'react';

import { refElementInterop } from './utils';

export const useListener = <
  T extends EventListenerOrEventListenerObject = EventListener,
>(
  ref: Ref<HTMLElement | Document | Window>,
  name: string,
  callback: T,
  enabled?: boolean,
  opts?: any
) => {
  useEffect(() => {
    if (enabled === false) return;

    const element = refElementInterop<HTMLElement | Document | Window>(ref);

    if (!element) return;

    element.addEventListener(name, callback, opts);

    return () => {
      element.removeEventListener(name, callback, opts);
    };
  }, [callback, enabled, name, ref, opts]);
};
