import SearchSpriteUrl from '@pepita-fe/sprite-search-module/sprite.svg';
import clsx from 'clsx';

import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import css from './styles.module.scss';

type OptionsItemProps = {
  icon: PepitaIconProps;
  label: string;
  description: string;
  onSelect: () => void;
  onHover?: () => void;
  active?: boolean;
};

export function OptionsItem({
  icon,
  label,
  description,
  onSelect,
  onHover,
  active = false,
}: OptionsItemProps) {
  // Using both onclick and onmousedown events for fixing mobile/desktop behavior
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li
      onClick={onSelect}
      onKeyDown={(event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
          event.preventDefault();
          onSelect();
        }
      }}
      onMouseDown={onSelect}
      onMouseMove={onHover}
      className={clsx(
        'nd-stackItem',
        css['in-searchOptionsItem__item'],
        active && 'is-focused'
      )}
    >
      {
        <PepitaIcon
          spriteUrl={SearchSpriteUrl}
          name={icon.name}
          customClass={clsx(
            'nd-stackItem__graphic',
            css['in-searchOptionsItem__graph']
          )}
        />
      }
      <span className="nd-stackItem__text">
        <span className={css['in-searchOptionsItem__label']}>{label}</span>
        {description ? (
          <span
            className={clsx(
              'nd-stackItem__secondaryText',
              css['in-searchOptionsItem__description']
            )}
          >
            {description}
          </span>
        ) : null}
      </span>
    </li>
  );
}
