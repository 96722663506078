import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext } from 'react';

import type { GeographySearchFeatureToggles } from './types';

const Context = createContext<GeographySearchFeatureToggles>({});

interface FeaturesProviderProps extends PropsWithChildren {
  features: GeographySearchFeatureToggles;
}

/** Simple provider to avoid terrible props drilling for feature toggles **/
export function FeaturesProvider({
  features,
  children,
}: FeaturesProviderProps) {
  return <Context.Provider value={features}>{children}</Context.Provider>;
}

export const useIsGeographyFeatureEnabled = () => {
  const context = useContext(Context);

  return useCallback(
    (feature: keyof GeographySearchFeatureToggles) => Boolean(context[feature]),
    [context]
  );
};
