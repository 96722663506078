import type { LatLngLiteral } from '@pepita-canary/leaflet';
import type { AddressAutocompleteValue } from 'src/repository/address-autocomplete/types';

import { getProductConfig } from 'src/config/product';

import { ENTITY_TYPE } from 'src/types/geography';
import type { GeographySearchFeatureToggles } from '../types';

import { http } from 'src/utils/client/http';
import type { Trans } from 'src/utils/i18nFormatters';

import { computeEntryLabel } from './labels';

export const autocompleteApi = (
  proximity: LatLngLiteral,
  query: string,
  trans: Trans,
  isGeographyFeatureEnabled: (
    feature: keyof GeographySearchFeatureToggles
  ) => boolean
) =>
  http('/api-next/geography/autocomplete', {
    searchParams: {
      macrozones: 1,
      proximity: `${proximity.lat},${proximity.lng}`,
      microzones: 1,
      min_level: isGeographyFeatureEnabled('metroSearchEnabled') ? 9 : 5,
      query,
      withRegions:
        isGeographyFeatureEnabled('regionSearchEnabled') || undefined,
      withCountries:
        isGeographyFeatureEnabled('countrySearchEnabled') || undefined,
      international:
        isGeographyFeatureEnabled('internationalSearchEnabled') || undefined,
    },
  })
    .json()
    .then((items) =>
      items.map((value) => ({
        ...value,
        ...computeEntryLabel(
          [value],
          isGeographyFeatureEnabled('labelsForCountry'),
          trans
        ),
        originalLabel: value.label,
        icon: value.type === ENTITY_TYPE.metro ? 'metro' : 'marker',
        children: value.macrozones || value.metroList, // TODO On marshaller!
        macrozones: undefined,
        metroList: undefined,
      }))
    );

const key = getProductConfig('geocodeAddressApiKey');
const country = getProductConfig('countryCode');
const defaultLocale = getProductConfig('defaultLocale');
const language = `${defaultLocale}-${country}`;

export const addressAutocompleteApi = (
  proximity: LatLngLiteral,
  query: string,
  mapBounds: string
) =>
  http('/api-next/address/autocomplete/', {
    searchParams: {
      q: query,
      proximity: `${proximity.lat},${proximity.lng}`,
      bbox: mapBounds,
      language,
      key,
      country,
    },
  }).json<AddressAutocompleteValue[]>();
