import { useCallback } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import type {
  AuthAction,
  AuthServiceProvider,
} from 'src/components/AuthModal/atoms/authModalAtom';
import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';

import type { ProductConfig } from 'src/config/product';
import { getProductConfig } from 'src/config/product';

import type { Language } from 'src/types/translations';

import { getAgencyUrl } from 'src/utils/getAgencySignupUrl';
import { alternateExternalUrl } from 'src/utils/url';

export const useUserConfigLink = () => {
  const { trans } = useTranslations();
  const { locale, defaultLocale } = useRouter() as {
    defaultLocale: Language;
    locale: Language;
  };

  const {
    savedAdsUrl,
    messagesUrl,
    blacklistedAdsUrl,
    publishedAdsUrl,
    appraisalUrl,
    lastAdsUrl,
    profileUrl,
    savedSearchesUrl,
    logoutUrl,
  }: ProductConfig['user'] = getProductConfig('user');
  const defaultLang: string = getProductConfig('defaultLocale');
  const appraisalHPUrl: string | undefined = getProductConfig(
    'evaluateBuildingUrl'
  )
    ? alternateExternalUrl(
        getProductConfig('evaluateBuildingUrl'),
        defaultLocale
      )
    : undefined;

  const sellerLeadUrl: string = getProductConfig('sellerLeadUrl');
  const country: string = getProductConfig('country');
  const publishedAdsLandingUrl: string = getProductConfig(
    'publishedAdsLandingUrl'
  );

  const houseFinancingUrl: Optional<string> = getProductConfig(
    'houseFinancingUrl'
  )
    ? alternateExternalUrl(
        getProductConfig('houseFinancingUrl'),
        defaultLocale,
        locale as Language,
        getProductConfig('external_crm_host')
      )
    : undefined;

  const linkConfig = {
    defaultLang,
    agencySignUp: getProductConfig('agencySignUp'),
    appraisalHPUrl,
    houseFinancingUrl,
    appraisalUrl,
    blacklistedAdsUrl,
    blog: getProductConfig('blog'),
    defaultCountry: getProductConfig('countryCode'),
    lastAdsUrl,
    messagesUrl,
    profileUrl,
    publishedAdsLandingUrl,
    publishedAdsUrl,
    savedAdsUrl,
    savedSearchesUrl,
    sellerLeadUrl,
    logoutUrl,
    cityGuideUrl: getProductConfig('cityGuideUrl'),
    insightsUrl: getProductConfig('insightsUrl'),
    mortgageUrl: getProductConfig('mortgageUrl'),
    homeLink: {
      //i18n-extract-keys ["lbl_search_italy", "lbl_search_spain", "lbl_search_greece", "lbl_search_luxembourg"]
      //i18n-extract-disable-next-line
      label: trans('lbl_search', { context: country }),
      event: country,
    },
    searchAgenciesUrl: getProductConfig('searchAgenciesUrl'),
    myHome: getProductConfig('myHomeUrl'),
  };

  const appConfig: ProductConfig['appConfig'] = getProductConfig('appConfig');
  const appStores: ProductConfig['appStores'] = getProductConfig('appStores');
  const mobileConfig = { appConfig, appStores };

  const userTabLinksConfig = {
    appraisalHPUrl,
    defaultLang,
    messagesUrl,
    publishedAdsLandingUrl,
    savedAdsUrl,
    sellerLeadUrl,
  };

  const setAuthModal = useSetAtom(authModalAtom);

  const handleSuccess = (action, service) => {
    if (
      ['apple', 'google', 'facebook'].some(
        (social) => service?.includes(social)
      ) ||
      action === 'login' ||
      action === 'signup'
    ) {
      setAuthModal({ open: false });
    }
  };

  const onLoginClick = useCallback(
    () =>
      setAuthModal({
        open: true,
        onClose: () => setAuthModal({ open: false }),
        onSuccess: (action: AuthAction, service?: AuthServiceProvider) => {
          handleSuccess(action, service);
        },
        showAdvantageInfo: true,
        contentType: 'default',
        agencySignUp: getAgencyUrl(locale, defaultLocale),
      }),
    [setAuthModal]
  );

  return { linkConfig, mobileConfig, onLoginClick, userTabLinksConfig };
};
