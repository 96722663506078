import { decode } from '@pepita/polyline';
import type { LatLngLiteral } from '@pepita-canary/leaflet';

import type { GeographySearch } from 'src/libs/geography';

import type {
  BaseEntityWithChildren,
  GeographyValue,
} from 'src/types/geography';

/**
 * As our apis do not write parents on all geography levels we need to
 * propagate them inside children recursively
 * @param parent
 * @param value
 */
export const enrichChildren = (
  parent: BaseEntityWithChildren,
  value: GeographyValue
) => {
  const entityWithParents = {
    ...value,
    parents: [parent, ...(parent.parents || [])],
  };

  if (!value?.children?.length) {
    return entityWithParents; // base case: we add the parents and return the entity
  }

  // Recursive case: we add the parents and do the same thing to the children
  return {
    ...entityWithParents,
    children:
      entityWithParents.children?.map((ch) => {
        return {
          ...enrichChildren(entityWithParents, ch),
        };
      }) || [],
  };
};

// Re-map place data coming from autocompletes or polygons into a format
// which could be provided to the geography atom
export const placeDataToGeography = (
  value: GeographyValue[]
): GeographySearch => ({
  searchType: 'place',
  value: value.map((e) => ({
    ...e,
    parents: e.parents ?? [],
    children: (e.children || []).map((child) => enrichChildren(e, child)),
  })),
});

/**
 * Given a serialized path from our polygons api it builds the list of coordinates
 */
export const deserializePolygonPath = (path: string[]): LatLngLiteral[][] =>
  path.map((polygon) => decode(polygon).map((p) => ({ lat: p[0], lng: p[1] })));
