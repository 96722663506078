import type { ReactNode, SyntheticEvent } from 'react';
import { useRef } from 'react';
import { clsx } from 'clsx';

import { usePreviousState } from 'src/hooks/usePreviousState';
import { useAnimateAccordion } from './useAnimateAccordion';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';

import { useImmer } from 'src/utils/immer';

export enum AccordionJSClasses {
  menu = 'js-accordion-menu',
  subMenu = 'js-accordion-subMenu',
  label = 'js-accordion-label',
}

export type AccordionItem = {
  text: ReactNode;
  content?: ReactNode;
  menuCustomClass?: string;
  labelCustomClass?: string;
};

type AccordionProps = {
  items: AccordionItem[];
  open?: Nullable<number | number[]>;
  onChange?: (selected: number) => void;
  customClass?: string;
};

const generateLabelDivProps = (
  hasContent: boolean,
  index: number,
  toggleItem: (index: number) => void
) => {
  if (!hasContent) {
    return {};
  }

  return {
    role: 'button',
    tabIndex: 0,
    onKeyDown: (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        toggleItem(index);
      }
    },
    onClick: (event: SyntheticEvent) => {
      event.preventDefault();
      event.stopPropagation();
      toggleItem(index);
    },
  };
};

export function Accordion({
  items,
  open,
  onChange,
  customClass,
}: AccordionProps) {
  const accordionRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line prefer-const
  let [accordionState, setAccordionState] = useImmer(() =>
    items.map(() => false)
  );

  if (open !== undefined && open !== null) {
    accordionState = items.map((_, i) =>
      Array.isArray(open) ? open.includes(i) : i === open
    );
  }

  const prevAccordionState = usePreviousState(accordionState);

  const toggleItem = (itemIndex: number) => {
    if (onChange) {
      return onChange(itemIndex);
    }

    setAccordionState((state) => {
      state[itemIndex] = !state[itemIndex];
    });
  };

  useAnimateAccordion(accordionState, prevAccordionState, accordionRef);

  return (
    <div className={clsx('nd-accordion', customClass)} ref={accordionRef}>
      {items.map((item, index) => {
        const hasContent = Boolean(item.content);
        const labelDivProps = generateLabelDivProps(
          hasContent,
          index,
          toggleItem
        );

        return (
          <div
            key={index}
            className={clsx(
              'nd-accordion__menu',
              accordionState[index] && 'is-open',
              AccordionJSClasses.menu,
              item.menuCustomClass
            )}
          >
            <div
              className={clsx(
                'nd-accordion__label',
                AccordionJSClasses.label,
                item.labelCustomClass
              )}
              {...labelDivProps}
            >
              {item.text}
              {hasContent && (
                <PepitaIcon
                  customClass="nd-accordion__icon"
                  name="arrow-right"
                />
              )}
            </div>
            {hasContent && (
              <div
                className={clsx(
                  'nd-accordion__subMenu',
                  AccordionJSClasses.subMenu
                )}
              >
                {item.content}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
