export const nonNullable = <T>(value: T): value is NonNullable<T> =>
  value !== null && value !== undefined;

type NonFalsy<T> = Exclude<T, null | undefined | '' | false | 0>;

export const nonFalsy = <T>(value: T): value is NonFalsy<T> => Boolean(value);

/**
 * Ensure that the given argument is not null or undefined, otherwise throw an
 * error.
 */
export const ensure = <T>(
  argument: T | undefined | null,
  message: string = 'This value was promised to be there.'
): T => {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
};
