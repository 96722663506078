import type {
  AreaSearchGeography,
  DistanceSearchCircleGeography,
  DistanceSearchIsolineGeography,
} from '../types/geography';
import type {
  AreaSearchGeoJson,
  DistanceSearchGeoJson,
} from '../types/geojson';

import { swapLatLng } from './helpers';
import { isDistanceSearchCircleFeature } from './validators';

export const buildAreaSearchGeography = (
  geoJsonObject: AreaSearchGeoJson
): AreaSearchGeography => {
  const { geometry } = geoJsonObject;

  return {
    searchType: 'polygon',
    value: {
      points: swapLatLng(geometry.coordinates[0]),
    },
  };
};

export const buildDistanceSearchGeography = (
  geoJsonObject: DistanceSearchGeoJson
): DistanceSearchCircleGeography | DistanceSearchIsolineGeography => {
  if (isDistanceSearchCircleFeature(geoJsonObject)) {
    const { geometry, properties } = geoJsonObject;
    const { radius } = properties;
    const [lng, lat] = geometry.coordinates;

    return {
      searchType: 'circle',
      value: {
        radius,
        center: [lat, lng],
      },
    };
  }

  const { geometry, properties } = geoJsonObject;
  const { label, time, transport, address, center } = properties;

  return {
    searchType: 'polygon',
    value: {
      type: 'Route',
      points: swapLatLng(geometry.coordinates[0]),
      center,
      label,
      time,
      transport,
      address,
    },
  };
};
