import { useMemo } from 'react';
import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { mapCenterAtom, mapZoomAtom } from 'src/components/MapComponent/atoms';
import { mapLoadedAtom } from 'src/components/MapComponent/atoms/mapLoadedAtom';

export const useMapQueryParams = () => {
  const mapLoaded = useAtomValue(mapLoadedAtom);
  const mapCenter = useAtomValue(mapCenterAtom);
  const mapZoom = useAtomicStateAtomValue(mapZoomAtom);

  const queryParams = useMemo(() => {
    if (!mapLoaded) {
      return;
    }

    return {
      lat: mapCenter ? mapCenter.lat : undefined,
      lng: mapCenter ? mapCenter.lng : undefined,
      zoom: mapZoom,
    };
  }, [mapCenter, mapLoaded, mapZoom]);

  return queryParams;
};
