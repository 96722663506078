import { escapeRegExp } from '@pepita/lotrash';
import { clsx } from 'clsx';

import { PepitaIcon } from 'src/libs/ui/pepita-icon';
import { PepitaListItem } from 'src/libs/ui/pepita-list';

import type { AutocompleteBaseValue } from '../types';

type ListItemProps<V extends AutocompleteBaseValue> = {
  item: V;
  highlighted: boolean;
  searchString: string;
  onItemHover?: () => void;
  onClick: (item: V) => void;
};

const highlightUserInput = (userInput: string, itemLabel: string) =>
  itemLabel.replace(
    new RegExp(escapeRegExp(userInput), 'i'),
    (match) => `<b>${match}</b>`
  );

export function AutocompleteListItem<V extends AutocompleteBaseValue>({
  item,
  highlighted,
  searchString,
  onItemHover,
  onClick,
}: ListItemProps<V>) {
  const { label, description, icon } = item;

  return (
    <PepitaListItem
      onMouseDown={() => onClick(item)}
      onMouseMove={onItemHover}
      title={description ? `${label} - ${description}` : label}
      customClass={clsx(
        'nd-stackItem',
        'nd-autocomplete__listItem',
        highlighted && 'is-focused'
      )}
    >
      {icon && (
        <span className="nd-stackItem__graphic">
          <PepitaIcon name={icon} />
        </span>
      )}

      <span className="nd-stackItem__text">
        <span
          dangerouslySetInnerHTML={{
            __html: highlightUserInput(searchString, label),
          }}
        />
        {description && (
          <span
            className="nd-stackItem__secondaryText"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        )}
      </span>
    </PepitaListItem>
  );
}
