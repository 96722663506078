import type { BaseEntity } from 'src/types/geography';
import { ENTITY_TYPE } from 'src/types/geography';
import type {
  AreaSearchGeography,
  DistanceSearchCircleGeography,
  DistanceSearchIsolineGeography,
  GeographySearch,
  PlaceSearchGeography,
} from '../types/geography';
import type {
  DistanceSearchCircle,
  DistanceSearchIsoline,
  SearchGeoJson,
} from '../types/geojson';

// Geography
export const isPlaceSearchGeography = (
  geography: GeographySearch
): geography is PlaceSearchGeography => geography.searchType === 'place';

export const isAreaSearchGeography = (
  geography: GeographySearch
): geography is AreaSearchGeography =>
  // The line of code below is the correct implementation with the new
  // TypeScript interface of the geography atom, but to avoid regressions, we
  // temporarily leave the previous implementation by adding ts-ignore to avoid
  // breaking the build.
  //
  // geography.searchType === 'polygon' && !('type' in geography.value);
  //
  // @ts-ignore
  geography.searchType === 'polygon' && geography.value.type !== 'Route';

export const isDistanceSearchCircleGeography = (
  geography: GeographySearch
): geography is DistanceSearchCircleGeography =>
  geography.searchType === 'circle';

export const isDistanceSearchIsolineGeography = (
  geography: GeographySearch
): geography is DistanceSearchIsolineGeography =>
  // The line of code below is the correct implementation with the new
  // TypeScript interface of the geography atom, but to avoid regressions, we
  // temporarily leave the previous implementation by adding ts-ignore to avoid
  // breaking the build.
  //
  // geography.searchType === 'polygon' && 'type' in geography.value;
  //
  // @ts-ignore
  geography.searchType === 'polygon' && geography.value.type === 'Route';

export const isDistanceSearchGeography = (
  geography: GeographySearch
): geography is
  | DistanceSearchCircleGeography
  | DistanceSearchIsolineGeography =>
  isDistanceSearchCircleGeography(geography) ||
  isDistanceSearchIsolineGeography(geography);

// GeoJson features
export const isDistanceSearchCircleFeature = (
  geoJsonObject: SearchGeoJson
): geoJsonObject is DistanceSearchCircle =>
  Boolean(
    geoJsonObject.geometry.type === 'Point' &&
      geoJsonObject.properties &&
      'radius' in geoJsonObject.properties
  );

export const isDistanceSearchIsolineFeature = (
  geoJsonObject: SearchGeoJson
): geoJsonObject is DistanceSearchIsoline =>
  Boolean(
    geoJsonObject.geometry.type === 'Polygon' &&
      geoJsonObject.properties &&
      'time' in geoJsonObject.properties
  );

export const isCity = ({ type }: BaseEntity) => type === ENTITY_TYPE.city;

export const isProvince = ({ type }: BaseEntity) =>
  type === ENTITY_TYPE.province;

export const isChildOfType = (entity: BaseEntity, type: ENTITY_TYPE) =>
  entity.parents.some((value) => value.type === type);
