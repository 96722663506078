import { useTranslations } from '@pepita-react/i18n';
import { useSetAtom } from 'jotai';

import { comeFromSearchOptionsAtom } from '../atoms/comeFromSearchOptionsAtom';

import { useComponentControls } from '../hooks/useComponentControls';

import { OptionsItem } from './OptionsItem';

export function DistanceFromAPoint() {
  const { trans } = useTranslations();
  const { openSearchModal } = useComponentControls();
  const setComeFromSearchOptions = useSetAtom(comeFromSearchOptionsAtom);

  return (
    <OptionsItem
      icon={{ name: 'option-position' }}
      label={trans('search_option_point_distance')}
      description={trans('search_option_point_distance_description')}
      onSelect={() => {
        setComeFromSearchOptions(true);
        openSearchModal('DISTANCE');
      }}
    />
  );
}
