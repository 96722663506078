import type { PropsWithChildren } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import {
  PepitaDialog,
  PepitaDialogContentFrameType,
  PepitaDialogFrameType,
} from 'src/libs/ui/pepita-dialog';

interface SearchOptionModalProps extends PropsWithChildren {
  onClose: () => void;
}

export function SearchOptionModal({
  onClose,
  children,
}: SearchOptionModalProps) {
  const { trans } = useTranslations();

  return (
    <PepitaDialog
      title={trans('search_option_header_title')}
      size={PepitaDialogFrameType.FULL}
      contentSize={PepitaDialogContentFrameType.FULL}
      contentNotScrollable
      onClose={onClose}
      content={children}
    />
  );
}
