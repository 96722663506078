import { useEffect, useState } from 'react';

export function usePepitaCustomElementImport(importCallback: () => any) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    // This check is done in order to skip this package evaluation on the server side
    if (typeof window !== 'undefined') {
      const module = importCallback();

      if (module?.then) {
        module.then(() => setReady(true));
      } else {
        setReady(true);
      }
    }
  }, []);

  return ready;
}
